import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import Logo from "../images/Logo.svg";
import { validateEmail } from "../utils";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import { postRequest } from "../api";
import { Link, useNavigate } from "react-router-dom";
import { CUSTOMER_ROUTES, FORGET_PASSWORD } from "../constants/FrontendRoutes";
import { LOGIN_TYPE } from "../constants";
import { setCurrentUser, setIsAuthenticated } from "../redux/authSlice";
import { setDataToLocalStorage } from "../utils/localStorage";
import { USERS_SIGN_IN } from "../constants/BackendRoutes";

const FORM_FIELDS = {
  email: "",
  password: "",
};

const Login = () => {
  const [formData, setFormData] = useState(FORM_FIELDS);
  const [googleLoginWidth, setGoogleLoginWidth] = useState(280);

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isFormValid =
    formData.email !== "" &&
    validateEmail(formData.email) &&
    formData.password !== "";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePostAuth = async (response) => {
    if (response && response.status === 200) {
      setFormData(FORM_FIELDS);
      dispatch(setIsAuthenticated(true));
      dispatch(setCurrentUser(response?.data));
      setDataToLocalStorage(response.headers?.authorization, response?.data?.id);
      navigate(CUSTOMER_ROUTES.ORDERS_OVERVIEW);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postRequest(USERS_SIGN_IN, {
        user: formData,
        login_type: LOGIN_TYPE.CUSTOMER,
      });
      await handlePostAuth(response);
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const handleGoogleOAuthSuccess = async (credentialResponse) => {
    const googleJwtToken = credentialResponse.credential;
    try {
      const response = await postRequest("auth/google_oauth", {
        token: googleJwtToken,
        login_type: LOGIN_TYPE.CUSTOMER,
      });
      await handlePostAuth(response);
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  useEffect(() => {
    const calculateWidth = () => {
      const screenWidth = window.innerWidth;
      const padding = 40;
      const maxWidth = screenWidth >= 1024 ? 320 : 380;
      const availableWidth = screenWidth - padding;
      setGoogleLoginWidth(Math.min(availableWidth, maxWidth));
    };

    calculateWidth();
    window.addEventListener("resize", calculateWidth);
    return () => {
      window.removeEventListener("resize", calculateWidth);
    };
  }, []);

  return (
    <div className="bg-[#F8F8F8] h-screen">
      <div className="flex flex-col justify-center items-center max-w-[1280px] relative mx-auto h-full">
        <img
          alt="logo"
          src={Logo}
          className="w-10 h-auto absolute top-5 lg:top-10 left-5"
        />

        <div className="flex flex-col items-center justify-center gap-7 w-full max-w-[420px] lg:max-w-max p-5">
          <p className="text-center text-[rgb(39,37,35)] text-2xl font-bold font-['Montserrat'] leading-[33.60px] w-full">
            Log into Coblr
          </p>
          <div className="flex flex-col lg:flex-row gap-6 w-full my-5">
            <form
              className="flex flex-col gap-6 lg:min-w-[320px]"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col gap-2">
                <label className="text-[#272523] text-xs font-normal font-['Questrial'] leading-none">
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  className="px-1 py-2 bg-transparent border-b border-black focus:outline-none"
                  placeholder="Example@email.com"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <label className="text-[#272523] text-xs font-normal font-['Questrial'] leading-none">
                    Password
                  </label>
                  <Link
                    to={FORGET_PASSWORD}
                    className="text-green-700 text-xs font-normal font-['SF Pro Display'] leading-tight tracking-tight"
                  >
                    Forget your password?
                  </Link>
                </div>
                <input
                  type="password"
                  name="password"
                  className="px-1 py-2 bg-transparent border-b border-black focus:outline-none"
                  placeholder="Please enter"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isFormValid}
              >
                Login
              </Button>
            </form>
            <div className="flex items-center w-full lg:flex-col lg:justify-center lg:w-fit">
              <hr className="flex-grow border-t border-gray-300 lg:border-t-0 lg:border-l lg:h-24 lg:w-0" />
              <span className="mx-2 text-gray-500 lg:my-2">or</span>
              <hr className="flex-grow border-t border-gray-300 lg:border-t-0 lg:border-l lg:h-24 lg:w-0" />
            </div>
            <div className="flex lg:items-center">
              <GoogleLogin
                size="large"
                width={googleLoginWidth}
                shape="pill"
                context="signin"
                useOneTap
                auto_select
                onSuccess={handleGoogleOAuthSuccess}
                onError={() => {
                  dispatch(addToast("Login Failed."));
                }}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2 text-center text-[#6e6e6e] text-xs font-normal font-['Helvetica Neue'] leading-none my-8">
            <span>Secure Login with reCAPTCHA subject to Google</span>
            <span>Terms & Privacy</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
