import React from "react";
import { LoaderIcon, ProgressIcon } from "../../../common/icons";
import { ORDER_TYPES } from "../../../constants";
import { OrderStatus } from "../../../constants/OrderStatus";
import { convertDateFormat, addDaysToSpecificDate } from "../../../utils";
import Chip from "../../Chip";

const COLORS = {
  DARK_GREY: '#272523',
  GREEN: '#12B76A',
  LIGHT_BROWN: '#F8E2C9',
  DARK_BROWN: '#CA8A68',
};

const orderStages = {
  [OrderStatus.NEW_ORDER]: {
    key: OrderStatus.NEW_ORDER,
    label: 'Order Created',
    icon: '✓',
    bgColor: COLORS.GREEN,
    color: COLORS.GREEN
  },
  [OrderStatus.SHIPPING]: {
    key: OrderStatus.SHIPPING,
    label: 'Shipping',
    icon: <LoaderIcon />,
    bgColor: COLORS.GREEN,
    color: COLORS.GREEN
  },
  [OrderStatus.IN_PROGRESS]: {
    key: OrderStatus.IN_PROGRESS,
    label: 'Repair in progress',
    icon: <ProgressIcon />,
    bgColor: COLORS.LIGHT_BROWN,
    color: COLORS.DARK_BROWN
  },
  [OrderStatus.DELAYED]: {
    key: OrderStatus.DELAYED,
    label: 'Repair in progress',
    icon: <ProgressIcon />,
    bgColor: COLORS.LIGHT_BROWN,
    color: COLORS.DARK_BROWN
  },
  [OrderStatus.REPAIRED]: {
    key: OrderStatus.REPAIRED,
    label: 'Order returned',
    icon: <LoaderIcon />,
    bgColor: COLORS.GREEN,
    color: COLORS.GREEN
  },
  [OrderStatus.DELIVERED]: {
    key: OrderStatus.DELIVERED,
    label: 'Package delivered',
    icon: '✓',
    bgColor: COLORS.GREEN,
    color: COLORS.GREEN
  },
};

const orderStagesConfig = {
  inStoreOrderStages: [
    orderStages[OrderStatus.NEW_ORDER],
    orderStages[OrderStatus.IN_PROGRESS],
    orderStages[OrderStatus.DELAYED],
    orderStages[OrderStatus.DELIVERED],
  ],
  onlineOrderStages: [
    orderStages[OrderStatus.NEW_ORDER],
    orderStages[OrderStatus.SHIPPING],
    orderStages[OrderStatus.IN_PROGRESS],
    orderStages[OrderStatus.DELAYED],
    orderStages[OrderStatus.REPAIRED],
    orderStages[OrderStatus.DELIVERED],
  ]
};

const OrderStatusTimeline = ({ order }) => {
  const { status, placement_type, created_at, shipping_date, in_progress_date, estimated_completion } = order;
  const isOnlineOrder = placement_type === ORDER_TYPES.ONLINE;
  const orderStages = isOnlineOrder ? orderStagesConfig.onlineOrderStages : orderStagesConfig.inStoreOrderStages;

  const getStageStatus = (stageKey, checkStatus) => {
    const stageIndex = orderStages.findIndex(stage => stage.key === status);
    const currentIndex = orderStages.findIndex(stage => stage.key === stageKey);
    return checkStatus(stageIndex, currentIndex);
  };

  const isStageCompleted = (stageKey) => getStageStatus(stageKey, (stageIndex, currentIndex) => currentIndex < stageIndex);

  const isStageActive = (stageKey) => getStageStatus(stageKey, (stageIndex, currentIndex) => currentIndex === stageIndex);

  const renderStatusLabel = (stage) => {
    return (
      <span
        style={{
          color: isStageCompleted(stage.key) ? COLORS.GREEN : isStageActive(stage.key) ? stage.color : '#C9C8C8',
        }}
        className="text-center absolute top-[-90%] w-[140px]"
      >
        {stage.label}
      </span>
    );
  };

  const renderStageIcon = (stage) => {
    return (
      <div
        style={{
          backgroundColor: isStageCompleted(stage.key) ? COLORS.GREEN : isStageActive(stage.key) ? stage.bgColor : '#C9C8C8',
          color: isStageCompleted(stage.key) || isStageActive(stage.key) ? 'white' : '#C9C8C8',
        }}
        className={`w-8 h-8 rounded-full flex items-center justify-center`}
      >
        {isStageCompleted(stage.key) ? '✓' : isStageActive(stage.key) ? stage.icon : ''}
      </div>
    );
  };

  const getStageDate = (baseDate, days) => {
    return baseDate ? addDaysToSpecificDate(baseDate, days) : null;
  };

  const renderStageDate = (stage) => {
    if (!created_at) return null;

    let displayText = '';
    let formattedDate = '';

    switch (stage.key) {
      case OrderStatus.NEW_ORDER:
        formattedDate = convertDateFormat(created_at);
        break;

      case OrderStatus.SHIPPING:
        if (isOnlineOrder) {
          displayText = !isStageCompleted(stage.key) ? 'Est. delivered on ' : '';
          formattedDate = convertDateFormat(shipping_date || getStageDate(created_at, 5));
        }
        break;

      case OrderStatus.IN_PROGRESS:
        displayText = !isStageCompleted(stage.key) ? 'Est. completed by ' : '';
        formattedDate = convertDateFormat(
          in_progress_date ||
          (shipping_date ? getStageDate(shipping_date, 5) : getStageDate(created_at, 10))
        );
        break;

      case OrderStatus.DELAYED:
        if (status === OrderStatus.DELAYED) {
          return <Chip status={status} />;
        }
        break;

      case OrderStatus.REPAIRED:
        if (isOnlineOrder) {
          displayText = !isStageCompleted(stage.key) ? 'Expected by ' : '';
          formattedDate = convertDateFormat(
            in_progress_date
              ? getStageDate(in_progress_date, 5)
              : shipping_date
                ? getStageDate(shipping_date, 10)
                : getStageDate(created_at, 15)
          );
        }
        break;

      case OrderStatus.DELIVERED:
        displayText = !isStageActive(stage.key) ? 'Expected ' : '';
        formattedDate = convertDateFormat(
          estimated_completion ? estimated_completion :
          in_progress_date
            ? getStageDate(in_progress_date, 10)
            : shipping_date
              ? getStageDate(shipping_date, 15)
              : getStageDate(created_at, 20)
        );
        break;

      default:
        return null;
    }

    return (
      <div style={{ color: COLORS.DARK_GREY }} className="text-xs mt-1">
        {displayText}{formattedDate}
      </div>
    );
  };

  const shouldShowStage = (stage) => {
    const conflictingStatuses = [
      { condition: stage.key === OrderStatus.IN_PROGRESS && status === OrderStatus.DELAYED },
      { condition: stage.key === OrderStatus.DELAYED && status === OrderStatus.IN_PROGRESS },
      { condition: stage.key === OrderStatus.DELAYED && status !== OrderStatus.IN_PROGRESS && status !== OrderStatus.DELAYED },
    ];

    return !conflictingStatuses.some(({ condition }) => condition);
  };

  return (
    <div className="p-8 md:p-0 bg-white md:bg-transparent mt-5">
      <div className="hidden md:flex items-center justify-between w-full py-16 px-16 bg-white rounded-lg">
        {orderStages.map((stage, index) => (
          shouldShowStage(stage) && (
            <React.Fragment key={stage.key}>
              <div key={stage.key} className="flex items-center justify-center relative">
                {renderStatusLabel(stage)}
                {renderStageIcon(stage)}
                {status !== OrderStatus.QUOTE && (
                  <div className="absolute top-[90%] w-[100px] mt-2 text-sm text-center">
                    {renderStageDate(stage)}
                  </div>
                )}
              </div>
              {index !== orderStages.length - 1 && <div className="flex-grow h-[2px] bg-gray-300 mr-[4px] ml-[4px]" />}
            </React.Fragment>
          )
        ))}
      </div>
      <div className="md:hidden flex flex-col py-8 px-4 bg-white rounded-lg border border-[#C9C8C8]">
        {orderStages.map((stage, index) => (
          shouldShowStage(stage) && (
            <div key={stage.key} className="relative flex items-start my-4">
              <div className="flex flex-col items-center">
                {renderStageIcon(stage)}
                {index !== orderStages.length - 1 && (
              <div className="absolute top-[50%] h-[90%] w-[1px] bg-gray-300"></div>
            )}
              </div>
                <div className="ml-4 -mt-2 mb-10">
                <div style={{
                  color: isStageCompleted(stage.key) ? COLORS.GREEN : isStageActive(stage.key) ? stage.color : '#C9C8C8' 
                  }} className="text-sm font-semibold">{stage.label}</div>
                <div className="text-xs text-gray-500 mt-1">{renderStageDate(stage)}</div>
              </div>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default OrderStatusTimeline;
