export const retrieveTokenFromLocalStorage = () => localStorage.getItem('token');

export const storeTokenToLocalStorage = (token) => localStorage.setItem('token', token);

export const destroyTokenFromLocalStorage = () => localStorage.removeItem('token');

export const retrieveUserIdFromLocalStorage = () => localStorage.getItem('id');

export const storeUserIdToLocalStorage = (id) => localStorage.setItem('id', id);

export const destroyUserIdFromLocalStorage = () => localStorage.removeItem('id');

export const retrieveDraftMessageFromLS = () => JSON.parse(localStorage.getItem('draftMessage'));
export const storeDraftMessageToLS = (message) => localStorage.setItem('draftMessage', message);
export const destroyDraftMessageFromLS = () => localStorage.removeItem('draftMessage');

export const setDataToLocalStorage = (token, id) => {
  storeTokenToLocalStorage(token);
  storeUserIdToLocalStorage(id);
}

export const destroyDataFromLocalStorage = () => {
  destroyTokenFromLocalStorage();
  destroyUserIdFromLocalStorage();
}
