export const OrderStatus = {
  QUOTE: "quote",
  NEW_ORDER: "new_order",
  SHIPPING: "shipping",
  IN_PROGRESS: "in_progress",
  DELAYED: "delayed",
  REPAIRED: "repaired",
  DELIVERED: "delivered",
};

export const OrderStatusMap = {
  [OrderStatus.QUOTE]: "Quote",
  [OrderStatus.NEW_ORDER]: "New Order",
  [OrderStatus.SHIPPING]: "Shipping",
  [OrderStatus.IN_PROGRESS]: "In Progress",
  [OrderStatus.DELAYED]: "Delayed",
  [OrderStatus.REPAIRED]: "Repaired",
  [OrderStatus.DELIVERED]: "Delivered",
};

export const initialStatuses = Object.keys(OrderStatus)
  .filter((key) => key !== "QUOTE")
  .map((key) => ({
    status: OrderStatus[key],
    subStatuses: [],
  }));

export const statusColors = {
  [OrderStatus.NEW_ORDER]: { background: "#F3F0E8", text: "#BF975F" },
  [OrderStatus.SHIPPING]: { background: "#E8DAF8", text: "#6A549E" },
  [OrderStatus.IN_PROGRESS]: { background: "#D8EDE0", text: "#5A8A6E" },
  [OrderStatus.DELAYED]: { background: "#F8E2C9", text: "#CA8A68" },
  [OrderStatus.REPAIRED]: { background: "#E8E8E8", text: "#272523" },
  [OrderStatus.DELIVERED]: { background: "#E8E8E8", text: "#272523" },
};
