import React from "react";
import Button from "@mui/material/Button";
import SuccessFrame from "../../images/success-frame.png";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../constants/FrontendRoutes";
import { updateRequest } from "../../api";
import { STORES } from "../../constants/BackendRoutes";

const Success = () => {
  const navigate = useNavigate();
  const userData = useSelector(selectCurrentUser);

  const handleDashboardMove = async () => {
    const response = await updateRequest(
      `${STORES}/${userData?.stores[0]?.id}/users/${userData?.id}`,
      {
        status: 0,
      }
    );
    if (response) {
      navigate(DASHBOARD);
    }
  };

  return (
    <div className="flex flex-col gap-3 m-8">
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px] flex flex-col">
        <span>🎉 Congratulations!</span>
        <span>Your account has been created</span>
      </div>

      <img src={SuccessFrame} />

      <div className="flex flex-col sm:flex-row gap-5 mt-5">
        <Button
          variant="containedPrimary"
          className="w-full"
          onClick={handleDashboardMove}
        >
          Continue to dashboard
        </Button>
      </div>
    </div>
  );
};

export default Success;
