import { getRequest, postRequest, deleteRequest } from "../api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  USERS_SIGN_IN,
  USERS_BASE,
  USERS_SIGN_OUT,
} from "../constants/BackendRoutes";
import { destroyDataFromLocalStorage } from "../utils/localStorage";
import { BASIC_STAFF_ROLES, All_STAFF_ROLES, USER_STATUS_MAPPER, LOGIN_TYPE } from "../constants";
import { Navigate } from "react-router-dom";

export const login = createAsyncThunk(
  "auth/login",
  async (params, { rejectWithValue }) => {
    try {
      let res = await postRequest(USERS_SIGN_IN, params);

      const firstStoreUser = res?.data?.stores_users.find(user => BASIC_STAFF_ROLES.includes(user.role)) || res?.data?.stores_users.find(user => All_STAFF_ROLES.includes(user.role));
      res.data.stores_users = firstStoreUser ? [firstStoreUser] : [];
      const foundStore = res.data.stores.find(store => store.id === res?.data?.stores_users?.[0]?.store_id);
      res.data.stores = foundStore ? [foundStore] : [];

      return {
        ...res.data,
        token: res.headers.authorization,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      await deleteRequest(USERS_SIGN_OUT);
      destroyDataFromLocalStorage();
      return true;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserData = createAsyncThunk(
  "auth/fetchUserData",
  async ({ id, login_type = LOGIN_TYPE.STAFF }, { rejectWithValue }) => {
    try {
      const associationToInclude =
        login_type === LOGIN_TYPE.STAFF
          ? "organization.subscription.plan,organization.addresses,stores.addresses,stores_users,stores.service_types,stores.categories,organization.subscription.add_on,organization.customize_taxes"
          : "stores_as_customer.store,addresses";

      const fields =
        "&fields=user.id,user.name,user.email,user.status,user.last_sign_in_at,user.last_sign_up,user.phone_number,user.user_name,user.avatar_url,user.pswd_mask";

      let userData = await getRequest(
        `${USERS_BASE}/${id}`,
        {},
        associationToInclude.concat(fields)
      );

      if (login_type === LOGIN_TYPE.STAFF) {
        const foundStoreUser =  userData.stores_users.find((user) => BASIC_STAFF_ROLES.includes(user.role)) || userData.stores_users.find((user) => All_STAFF_ROLES.includes(user.role));
        userData.stores_users = foundStoreUser ? [foundStoreUser] : [];
        const foundStore = userData?.stores?.find((store) => store.id === userData?.stores_users?.[0]?.store_id);
        userData.stores = userData.stores_users[0] ? [foundStore] : [];
      }

      return userData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
