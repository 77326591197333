import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    isLoading: false,
    progressBar: {
      display: false,
      value: 0,
      successMessage: "",
    },
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateProgressBar: (state, action) => {
      const { display, value, successMessage } = action.payload;
      if (display !== undefined) {
        state.progressBar.display = display;
      }
      if (value !== undefined) {
        state.progressBar.value = value;
      }
      if (successMessage !== undefined) {
        state.progressBar.successMessage = successMessage;
      }
    },
  },
});

export const { setLoading, updateProgressBar } = loadingSlice.actions;
export default loadingSlice.reducer;
