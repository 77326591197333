import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Divider, IconButton } from "@mui/material";
import MediaQuery, { useMediaQuery } from "react-responsive";

import Price from "./Price";
import CartBadge from "../CartBadge";
import ProductItem from "./ProductItem";
import ServiceItem from "./ServiceItem";
import SwitchIosStyle from "../../../Switch/SwitchIosStyle";
import DropdownButton from "../../../Button/DropdownButton";
import { TrashIcon2 } from "../../../../common/icons";
import { groupedBy } from "../../../../utils";
import { CART_TYPES, ORDER_TYPE_ENUM, POS_NEW_ORDER_STEPS } from "../../../../constants";
import { selectCurrentUser } from "../../../../redux/authSlice";
import { CUSTOMER_DETAILS } from "../../../../constants/FrontendRoutes";
import { createPayment, posCreateOrder } from "../../../../api/pos";
import MenuOptionButton from '../../../Button/MenuOptionButton';
import { deleteRequest } from "../../../../api";
import { addToast } from "../../../../redux/toastSlice";

const CartDetail = ({
  defaultCart,
  cart,
  setCart,
  setReplica,
  editMode,
  setEditMode,
  duplicateMode,
  setDuplicateMode,
  currentStep,
  setCurrentStep,
}) => {
  const { serviceLineItems, productLineItems } = cart;
  const groupedProductItemsByCategory = groupedBy(productLineItems, "category");

  const [editingId, setEditingId] = useState("");
  const [trackServiceItemPrices, setTrackServiceItemPrices] = useState(null);

  const [enableStripeCharge, setEnableStripeCharge] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const handleDropdownClick = ({ cartType }) => {
    if (cart?.attached_customer) {
      setCart({ ...cart, type: cartType });
      createPayment(cart, setCart, userData, dispatch);

      if (cartType === CART_TYPES.checkout) {
        setCurrentStep(POS_NEW_ORDER_STEPS.PAYMENT_METHOD);
      } else if (cartType === CART_TYPES.quote) {
        posCreateOrder(
          ORDER_TYPE_ENUM.online,
          cart,
          setCart,
          setCurrentStep,
          userData,
          dispatch
        );
      }
    } else {
      setCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CUSTOMER);
    }
  };

  const handleEditServiceLineItem = () => {
    setCart((prev) => ({
      ...prev,
      serviceLineItems: prev.serviceLineItems.map((item) =>
        item.uniqStr === trackServiceItemPrices.uniqStr
          ? trackServiceItemPrices
          : item
      ),
    }));
    setEditMode(false);
    setEditingId("");
    setTrackServiceItemPrices(null);
  };

  const menuButtonConfig = [
    {
      name: "Attach customer",
      function: () => setCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CUSTOMER),
      disabled:
        currentStep !== POS_NEW_ORDER_STEPS.ADD_ITEM &&
        currentStep !== POS_NEW_ORDER_STEPS.ORDER_CART &&
        currentStep !== POS_NEW_ORDER_STEPS.CREATE_NEW_DISCOUNT &&
        currentStep !== POS_NEW_ORDER_STEPS.ADD_DISCOUNT,
    },
    {
      name: "Add discount",
      function: () => setCurrentStep(POS_NEW_ORDER_STEPS.ADD_DISCOUNT),
      disabled:
        currentStep !== POS_NEW_ORDER_STEPS.ADD_ITEM &&
        currentStep !== POS_NEW_ORDER_STEPS.ORDER_CART &&
        currentStep !== POS_NEW_ORDER_STEPS.SELECT_CUSTOMER &&
        currentStep !== POS_NEW_ORDER_STEPS.CREATE_CUSTOMER,
    },
    {
      name: "Discard",
      disabled: currentStep == POS_NEW_ORDER_STEPS.SCAN_CARD,
      function: async () => {
        try {
          if (cart?.payment_id) {
            await deleteRequest(
              `stores/${userData.stores[0].id}/payments/${cart?.payment_id}`
            );
          }
        } catch (error) {
          dispatch(addToast(error));
        } finally {
          setCart((prev) => ({
            ...defaultCart,
            productSalesTaxRate: prev.productSalesTaxRate,
            serviceSalesTaxRate: prev.serviceSalesTaxRate,
            rushOrder: { ...prev.rushOrder, isRush: false },
          }));
          setCurrentStep(
            isDesktopScreen
              ? POS_NEW_ORDER_STEPS.ADD_ITEM
              : POS_NEW_ORDER_STEPS.ORDER_CART
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (userData) {
      setEnableStripeCharge(
        userData?.organization?.connect_acc_charges_enabled
      );
    }
  }, [userData?.organization]);

  return (
    <div className="flex flex-col w-full sm:w-auto min-w-[330px] justify-between font-normal font-questrial lg:p-5 text-base bg-[#F8F8F8] lg:bg-white rounded-lg h-full sm:mx-auto">
      <div className="flex flex-col gap-4 flex-grow overflow-auto">
        <CartBadge
          cart={cart}
          setCart={setCart}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />

        <div className="flex flex-col justify-between gap-1">
          {cart.attached_customer && (
            <Link
              to={CUSTOMER_DETAILS.replace(":id", cart?.attached_customer?.id)}
              className="flex gap-2 justify-end lg:justify-start"
            >
              <span className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
                Customer:
              </span>
              <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug hover:text-[#4C8C4A] w-fit">
                {cart?.attached_customer?.name}
              </span>
            </Link>
          )}
          <MediaQuery minWidth={1024}>
            <div className="flex justify-between gap-4 items-center">
              <p className="text-[#272523] text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                Cart (
                {(cart?.serviceLineItems?.reduce(
                  (acc, item) => acc + item.quantity,
                  0
                ) || 0) +
                  (cart?.productLineItems?.reduce(
                    (acc, item) => acc + item.quantity,
                    0
                  ) || 0)}{" "}
                items)
              </p>
              <MenuOptionButton data={menuButtonConfig} />
            </div>
          </MediaQuery>
        </div>

        <div className="flex flex-col border rounded-lg overflow-y-auto">
          <div
            className={`${
              editMode ? "px-3 py-[10px]" : "px-3 py-4"
            } flex flex-row justify-between items-center  text-[#272523] text-xs`}
          >
            <span>Item detail</span>
            {editMode && (
              <span className="flex gap-2">
                <Button
                  variant="outlinedSecondary"
                  onClick={() => {
                    setEditMode(false);
                    setEditingId("");
                    setTrackServiceItemPrices(null);
                  }}
                  sx={{
                    padding: "4px 10px",
                    minWidth: "auto",
                    fontSize: "12px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="containedPrimary"
                  onClick={handleEditServiceLineItem}
                  sx={{
                    padding: "4px 10px",
                    minWidth: "auto",
                    fontSize: "12px",
                  }}
                >
                  Save
                </Button>
              </span>
            )}
          </div>
          {serviceLineItems?.length > 0 || productLineItems?.length > 0 ? (
            <>
              {serviceLineItems?.map((serviceLineItem, lineItemIndex) => (
                <ServiceItem
                  item={serviceLineItem}
                  index={lineItemIndex}
                  setCart={setCart}
                  setReplica={setReplica}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  setDuplicateMode={setDuplicateMode}
                  editingId={editingId}
                  setEditingId={setEditingId}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setTrackServiceItemPrices={setTrackServiceItemPrices}
                />
              ))}

              {Object.keys(groupedProductItemsByCategory)?.map((key) => (
                <React.Fragment key={key}>
                  <Divider />
                  <div className="my-2 flex flex-col gap-1">
                    <h2 className="mx-4 text-xs text-[#5D5C5A]">{key}</h2>
                    <div>
                      {groupedProductItemsByCategory[key].map(
                        (productLineItem) => (
                          <ProductItem
                            item={productLineItem}
                            setCart={setCart}
                            setReplica={setReplica}
                            editingId={editingId}
                            setEditingId={setEditingId}
                            setEditMode={setEditMode}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                          />
                        )
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}

              <Divider />
              <div className="flex gap-4 justify-between items-center mx-4 my-5 text-[#272523]">
                <div className="flex flex-row gap-2 items-center">
                  <p className="mr-3">Rush Order</p>
                  <SwitchIosStyle
                    checked={cart?.rushOrder?.isRush}
                    disabled={
                      isDesktopScreen &&
                      currentStep != POS_NEW_ORDER_STEPS.ADD_ITEM
                    }
                    onChange={(e) =>
                      setCart((prev) => ({
                        ...prev,
                        rushOrder: {
                          ...prev.rushOrder,
                          isRush: e.target.checked,
                        },
                      }))
                    }
                  />
                </div>
                <span>${parseFloat(cart?.rushOrder?.price).toFixed(2)}</span>
              </div>

              {!!cart?.discount && (
                <>
                  <Divider />
                  <div className="mx-4 my-5">
                    <h3 className="text-xs text-[#5D5C5A] mb-1">Discount</h3>
                    <div className="flex gap-4 justify-between items-center text-[#272523]">
                      <p>{cart?.discount?.name}</p>
                      <div className="flex gap-2 items-center">
                        <span>- ${cart.totalDiscount.toFixed(2)}</span>
                        <IconButton
                          size="small"
                          onClick={() => setCart({ ...cart, discount: null })}
                          disabled={
                            isDesktopScreen &&
                            currentStep != POS_NEW_ORDER_STEPS.ADD_ITEM
                          }
                        >
                          <TrashIcon2 fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <Divider />
              <p className="px-3 py-5 text-center text-[#272523]">
                Nothing added yet
              </p>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-none flex-col gap-8 justify-end">
        <Price cart={cart} />
        <div className="flex justify-between w-full">
          <DropdownButton
            button={{
              text: "Check Out",
              returnedValue: {
                cartType: CART_TYPES.checkout,
              },
            }}
            menuItems={[
              {
                text: "Check out",
                returnedValue: {
                  cartType: CART_TYPES.checkout,
                },
              },
              {
                text: "Create a quote",
                returnedValue: {
                  cartType: CART_TYPES.quote,
                },
                disabled: !enableStripeCharge,
              },
            ]}
            onClick={(returnedValue) => handleDropdownClick(returnedValue)}
            disabled={
              (!cart?.serviceLineItems?.length &&
                !cart?.productLineItems?.length) ||
              (isDesktopScreen &&
                currentStep !== POS_NEW_ORDER_STEPS.ADD_ITEM) ||
              editMode ||
              editingId
            }
          />
          <MediaQuery maxWidth={1023}>
            <MenuOptionButton data={menuButtonConfig} />
          </MediaQuery>
        </div>
      </div>
    </div>
  );
};

export default CartDetail;
