import React from "react";
import { Checkbox } from "@mui/material";
const notificationsOptions = [
  { id: 1, label: "Order status updates" },
  { id: 2, label: "Communication from the Coblr’s team" },
  { id: 3, label: "Advertisement of new product/services" },
];

const Notifications = () => {
  return (
    <div className="hidden w-full p-[32px] px-[40px] rounded-lg bg-white shadow-md mt-10">
      <h2 className="text-[18px] font-semibold text-left text-[#272523] mb-2">
        Notifications
      </h2>
      <p className="text-lg font-normal text-left text-[#272523] mb-4">
        Send email as notifications for the following updates:
      </p>
      <div className="flex flex-col gap-4">
        {notificationsOptions.map((option) => (
          <div key={option.id} className="flex items-center gap-2">
            <Checkbox disabled defaultChecked />
            <span className="font-questrial text-lg font-normal text-[#272523]">
              {option.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Notifications;
