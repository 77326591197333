import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Outlet,
  useNavigate,
  useLocation,
  matchPath,
} from "react-router-dom";
import { logout } from "../../redux/authActions";
import Logo from "../../images/Logo.svg";
import { CUSTOMER_ROUTES } from "../../constants/FrontendRoutes";
import MenuOptionButton from "../Button/MenuOptionButton";
import { ArrowDownIcon } from "../../common/icons";
import BasicTabs from "../../common/components/Tabs";
import { CUSTOMER_DASHBOARD_TAB_LIST } from "../../constants";
import { fetchUnreadCount } from '../../redux/messageAction';

const CustomerLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentTab, setCurrentTab] = useState(
    CUSTOMER_DASHBOARD_TAB_LIST[0].value
  );
  const unreadCount = useSelector((state) => state.message.unreadMessages);
  const buttonConfig = [
    {
      name: "My account",
      function: () => navigate(CUSTOMER_ROUTES.MY_ACCOUNT),
    },
    {
      name: "Logout",
      function: () => dispatch(logout()),
    },
  ];
  const isMyAccountRoute = location.pathname === CUSTOMER_ROUTES.MY_ACCOUNT;
  const isMyOrderDetailsRoute = matchPath(
    {
      path: CUSTOMER_ROUTES.ORDERS_DETAILS,
      exact: false,
      strict: false,
    },
    location.pathname
  );

  const handleTabChange = (newValue) => {
    const newTab = CUSTOMER_DASHBOARD_TAB_LIST[newValue];
    setCurrentTab(newTab?.value);
    navigate(newTab.route);
  };

  useEffect(() => {
    const currentTabIndex = CUSTOMER_DASHBOARD_TAB_LIST.findIndex(
      (tab) => tab.route === location.pathname
    );
    const tab = CUSTOMER_DASHBOARD_TAB_LIST[currentTabIndex];
    setCurrentTab(tab?.value);
  }, [location.pathname]);

  useEffect(async() => {
    dispatch(fetchUnreadCount());
  }, []);

  return (
    <div className="bg-[#F8F8F8] h-screen">
      <div className="mx-auto max-w-[1280px]">
        <div className="p-5 flex justify-between items-center w-full border-b">
          <div className="flex items-center">
            <div className="flex items-center gap-3">
              <Link to={CUSTOMER_ROUTES.ORDERS_OVERVIEW}>
                <img alt="logo" src={Logo} className="w-9 lg:w-10 h-auto" />
              </Link>
              <BasicTabs
                list={CUSTOMER_DASHBOARD_TAB_LIST.map((tab) => (
                  <div className="flex items-center">
                    <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
                      {tab.label}
                    </p>
                    {tab.value === CUSTOMER_DASHBOARD_TAB_LIST[1].value && unreadCount > 0 && (
                      <span className="w-5 h-5 flex items-center justify-center rounded-full ml-2 p-2 bg-[#D84141] text-white">
                        {unreadCount}
                      </span>
                    )}
                  </div>
                ))}
                setValue={handleTabChange}
                value={CUSTOMER_DASHBOARD_TAB_LIST.findIndex(
                  (tab) => tab.value === currentTab
                )}
              />
            </div>
            {isMyAccountRoute && (
              <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
                <KeyboardArrowRightIcon />
                My Account
              </p>
            )}
            {isMyOrderDetailsRoute && (
              <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
                <KeyboardArrowRightIcon />
                Order Details
              </p>
            )}
          </div>
          <MenuOptionButton
            title="My Account"
            data={buttonConfig}
            icon={<ArrowDownIcon fill="black" />}
            isMenuHorizontal={false}
          />
        </div>
        <Outlet />
      </div>
    </div>
  );
};
export default CustomerLayout;
