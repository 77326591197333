import { Box, Button, CircularProgress, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { calculateTotal } from "../../../utils";
import { ORDER_TYPE_ENUM, PAYMENT_STATUS_ENUM, POS_NEW_ORDER_STEPS } from "../../../constants";
import { getRequest, postRequest, updateRequest } from "../../../api";
import FieldItem from "../../Form/FieldItem";
import BlockIcon from "@mui/icons-material/Block";
import AutoCompleteInput from "../../Form/Field/AutoComplete";
import { useDispatch } from "react-redux";
import { addToast } from "../../../redux/toastSlice";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/authSlice";
import { useActionCable } from "../../../customHooks/useActionCable";
import { GreenTick, RedCross } from "../../../common/icons";
import { createPayment, posCreateOrder } from "../../../api/pos";
import CartBadge from "./CartBadge";

const ScanCard = ({ cart, setCart, currentStep, setCurrentStep }) => {
  const [listTerminalReaders, setListTerminalReaders] = useState([]);
  const [currentReader, setCurrentReader] = useState();
  const [paymentIntentID, setPaymentIntentID] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [enableSimulateReader, setEnableSimulateReader] = useState(false);
  const [enableNextStep, setEnableNextStep] = useState(false);

  const [paymentResponseSucceeded, setPaymentResponseSucceeded] = useState(false);
  const [paymentResponseMessage, setPaymentResponseMessage] = useState("");

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const [paymentResponse] = useActionCable("PaymentChannel", { payment_id: cart.payment_id });

  useEffect(() => {
    if (paymentResponse.status === "succeeded") {
      setPaymentResponseMessage(paymentResponse.message);
      setPaymentResponseSucceeded(true);
    } else if (paymentResponse.status === "requires_payment_method" || paymentResponse.status === "intent_invalid_state") {
      setPaymentResponseMessage(paymentResponse.message);
      setPaymentResponseSucceeded(false);
      setEnableNextStep(false);
    }
    setButtonLoading(false);

  }, [paymentResponse]);

  const handleBack = async () => {
    setCart((prev) => ({ ...prev, paymentMethod: null }));
    setCurrentStep(POS_NEW_ORDER_STEPS.PAYMENT_METHOD);
  };

  const retrieveTerminalReaders = async () => {
    try {
      const response = await getRequest("stripe/terminal_readers");
      const readers = response?.terminal_readers;
      if (readers?.length === 1) {
        const [reader] = readers;
        setCurrentReader({
          title: reader?.label,
          description: reader?.device_type,
          value: reader?.id,
        });
      }
      setListTerminalReaders(response.terminal_readers);
    } catch (error) {
      console.error("Error fetching terminal readers:", error);
    }
  };

  const createPaymentIntent = async () => {
    setButtonLoading(true);
    setPaymentResponseMessage("");
    setPaymentResponseSucceeded(false);

    try {
      const response = await postRequest(
        `stripe/payment_intents`,
        {
          organization_id: userData?.organization?.id,
          amount: calculateTotal(cart) * 100,
          currency: "usd",
          payment_method_types: ["card_present"],
          capture_method: "automatic",
          payment_id: cart.payment_id,
        }
      );

      if (response && response.status === 200) setPaymentIntentID(response.data.paymentIntentId);
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      setButtonLoading(false);
    }
  }

  const handleCardScanning = async () => {
    setButtonLoading(true);
    setPaymentResponseMessage("");
    if (!cart.payment_id) {
      createPayment(cart, setCart, userData, dispatch);
    }

    try {
      const response = await postRequest(`stripe/terminal_readers/process`, {
        reader_id: currentReader.value,
        payment_intent_id: paymentIntentID,
      });

      if (response && response.status == 200) {
        if (response.data?.reader_action_status == "in_progress")
          dispatch(addToast("Insert customer card."));
          setEnableSimulateReader(true);
          setEnableNextStep(true);
      }
    } catch (error) {
      dispatch(addToast(error));
      setButtonLoading(false);
    }
  }

  const handleSimulateReader = async () => {
    try {
      setButtonLoading(true);
      const response = await postRequest(
        `stripe/terminal_readers/simulate`,
        {
          reader_id: currentReader.value,
        }
      );

      if (response && response.status == 200) {
        setEnableSimulateReader(false);
      }
    } catch (error) {
      dispatch(addToast(error));
      setButtonLoading(false);
    }
  }

  const handleFinalize = () => {
    posCreateOrder(
      ORDER_TYPE_ENUM.in_store,
      cart,
      setCart,
      setCurrentStep,
      userData,
      dispatch
    );
  }

  useEffect(() => {
    retrieveTerminalReaders();
    createPaymentIntent();
  }, [])

  return (
    <Box className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[55%] sm:m-auto">
        <div className="flex flex-col w-full justify-between">
          <div className="flex justify-between items-center my-4">
            <span className="text-base lg:text-2xl lg:font-bold font-normal font-['Questrial'] leading-snug">
              Total amount due
            </span>
            <span className="text-lg font-semibold lg:text-2xl lg:font-bold font-['Montserrat'] leading-[25.20px]">
              ${calculateTotal(cart).toFixed(2)}
            </span>
          </div>

          <hr className="my-3 md:my-5" />

          <Box sx={{ display: "flex", flexDirection: "column", gap: 3, my: 3 }}>
            <FieldItem>
              <Typography width="50%" variant="body">
                Select the reader
              </Typography>
              <AutoCompleteInput
                label="Please select"
                options={listTerminalReaders.map((reader) => ({
                  title: reader.label,
                  description: reader.device_type,
                  value: reader.id,
                }))}
                value={currentReader}
                onChange={(_e, value) => setCurrentReader(value)}
              />
            </FieldItem>
            <FieldItem>
              <Typography width="50%" variant="body">
                Payment key
              </Typography>
              <TextField
                placeholder="Hit generate payment intent"
                variant="outlined"
                size="small"
                disabled
                value={paymentIntentID}
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <BlockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FieldItem>
          </Box>
          {currentReader?.description?.includes("simulated") && (
            <div className="flex flex-row justify-end items-center">
              <Button
                variant="textSecondary"
                disabled={!enableSimulateReader}
                onClick={handleSimulateReader}
                startIcon={<Add />}
              >
                Simulate stripe reader
              </Button>
            </div>
          )}
          {paymentResponseMessage && (
            <div className="flex gap-2 items-center w-fit ml-auto">
              {paymentResponseSucceeded ? <GreenTick /> : <RedCross />}
              <p
                className={`text-xs font-normal font-['Questrial'] leading-none ${
                  paymentResponseSucceeded ? "text-green-700" : "text-[#D9534F]"
                }`}
              >
                {paymentResponseMessage}
              </p>
            </div>
          )}
        </div>

        <Box
          className="flex flex-col justify-between w-full gap-2"
          sx={{ mt: 3 }}
        >
          {enableNextStep ? (
            <Button
              variant="containedPrimary"
              className="flex-1"
              disabled={buttonLoading}
              onClick={handleFinalize}
            >
              {buttonLoading ? (
                <CircularProgress sx={{ color: "white" }} size={20} />
              ) : (
                "Done"
              )}
            </Button>
          ) : (
            <Button
              variant="containedPrimary"
              className="flex-1"
              disabled={!(paymentIntentID && currentReader)}
              onClick={handleCardScanning}
            >
              {buttonLoading ? (
                <CircularProgress sx={{ color: "white" }} size={20} />
              ) : (
                "Scan card"
              )}
            </Button>
          )}
          <Button
            variant="outlinedSecondary"
            className="flex-1"
            onClick={handleBack}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ScanCard;
