import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../api";

export const fetchServiceTypes = createAsyncThunk(
  "store/service_types",
  async (store_id, { rejectWithValue }) => {
    try {
      let service_types = await getRequest(`stores/${store_id}/service_types`);

      return service_types;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMainCategories = createAsyncThunk(
  "store/categories",
  async (store_id, { rejectWithValue }) => {
    try {
      let categories = await getRequest(`stores/${store_id}/categories`);

      return categories;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSubStatuses = createAsyncThunk(
  'fetchSubStatuses',
  async (storeId) => {
    try {
      const data = await getRequest(`/stores/${storeId}/sub_statuses`);
      return data;
    } catch (error) {
      console.error('Failed to fetch sub statuses', error);
    }
  }
);
