import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector, useDispatch } from "react-redux";
import { setLoading, updateProgressBar } from "../redux/loadingSlice";

const LoadingOverlay = () => {
  const dispatch = useDispatch();
  const { isLoading, progressBar } = useSelector((state) => state.loading);

  const handleCompleteClick = () => {
    dispatch(setLoading(false));
    dispatch(updateProgressBar({ display: false, value: 0 }));
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-[5000]">
          {progressBar?.display ? (
            <div className="flex flex-col justify-center w-4/5 md:w-1/2 gap-2">
              <div className="flex items-center gap-2">
                <LinearProgress
                  variant="determinate"
                  value={progressBar?.value}
                  sx={{
                    flexGrow: 1,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#fff",
                    },
                  }}
                />
                <p className="text-sm text-white text-right">
                  {`${Math.round(progressBar?.value)}%`}
                </p>
              </div>
              {progressBar?.value === 100 ? (
                <div className="flex flex-col text-sm text-white text-center gap-2">
                  <span>{ progressBar?.successMessage }</span>
                  <span
                    onClick={handleCompleteClick}
                    className="text-white underline animate-zoom cursor-pointer"
                  >
                    Click to go back.
                  </span>
                </div>
              ) : (
                <div className="flex flex-col text-sm text-white text-center">
                  <span>The process may take some time...</span>
                  <span>Stay with us!</span>
                </div>
              )}
            </div>
          ) : (
            <CircularProgress sx={{ color: "white" }} size={75} />
          )}
        </div>
      )}
    </>
  );
};

export default LoadingOverlay;
