import { Close } from "@mui/icons-material";
import React from "react";

const truncateFileName = (name, maxLength = 10) => {
  if (name.length <= maxLength) return name;
  const extIndex = name.lastIndexOf(".");
  const ext = name.slice(extIndex);
  const truncated = name.slice(0, maxLength - ext.length) + "..." + ext;
  return truncated;
};

const Attachment = ({ imageUrl, fileName, onRemove, index }) => {
  const truncatedName = React.useMemo(() => truncateFileName(fileName), [fileName]);

  return (
    <div className="relative flex items-center border rounded-xl border-gray-300 p-1 bg-white shadow-sm max-w-[220px] min-w-[150px]">
      <div className="w-[30%] min-w-[50px] max-w-[60px] h-auto max-h-14 p-1">
        <img
          src={imageUrl}
          alt={`attachment-${index}`}
          className="w-full h-auto max-h-12 rounded-lg object-cover"
        />
      </div>

      <div className="w-[70%] flex items-center justify-between pl-1 min-w-[100px]">
        <span className="truncate text-xs font-medium text-gray-700">
          {truncatedName}
        </span>

        <button
          onClick={() => onRemove(index)}
          className="absolute top-0 right-0 bg-white hover:bg-gray-100 text-black rounded-full p-1 -translate-y-2 translate-x-2 shadow-lg border-gray-100"
          style={{ width: "20px", height: "20px" }}
          aria-label="Remove attachment"
        >
          <Close
            style={{ fontSize: "14px" }}
            className="-translate-y-2 -translate-x-[1px] text-gray-400"
          />
        </button>
      </div>
    </div>
  );
};

export default React.memo(Attachment);