import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BaseList from "../../common/components/Conversations/BaseList";
import { CONVERSATION_DETAIL } from "../../constants/FrontendRoutes";

const List = ({
  storeId,
  userData,
  conversationsData,
  searchInput,
  setCreateNewMessage,
  isAllMessagesSelected,
  isUnreadMessagesSelected,
  setIsDetailVisible,
  setSelectedConversation,
}) => {
  const { id } = useParams();
  const conversationId = parseInt(id);

  const [conversations, setConversations] = useState([]);
  const [allConversations, setAllConversations] = useState([]);

  const conversationFilter = () => {
    let filteredConversations = allConversations;

    if (!isAllMessagesSelected && !isUnreadMessagesSelected) {
      filteredConversations = [];
    } else {
      if (isUnreadMessagesSelected && !isAllMessagesSelected) {
        filteredConversations = allConversations.filter(
          (conversation) => conversation.highlighted
        );
      } else if (isAllMessagesSelected && !isUnreadMessagesSelected) {
        filteredConversations = allConversations;
      } else if (isUnreadMessagesSelected && isAllMessagesSelected) {
        filteredConversations = allConversations;
      }

      filteredConversations = filteredConversations.filter((conversation) => {
        const matchesSearch = conversation.displayName
          .toLowerCase()
          .includes(searchInput.toLowerCase());

        return matchesSearch;
      });
    }
    return filteredConversations;
  };

  const handleClickFunctions = (conversation) => {
    setSelectedConversation(conversation);
    setCreateNewMessage(false);
    setIsDetailVisible(true);
  };

  useEffect(() => {
    setConversations(conversationsData);
    setAllConversations(conversationsData);
  }, [conversationsData]);

  useEffect(() => {
    const filteredConversations = conversationFilter();

    setConversations(filteredConversations);
  }, [
    searchInput,
    isAllMessagesSelected,
    isUnreadMessagesSelected,
    allConversations,
  ]);

  return (
    <BaseList
      isCustomer={false}
      storeId={storeId}
      userData={userData}
      conversations={conversations}
      conversationId={conversationId}
      navigationLink={CONVERSATION_DETAIL}
      setConversations={setConversations}
      handleClickFunctions={handleClickFunctions}
    />
  );
};

export default List;
