import React, { useEffect, useRef, useState } from "react";
import { CrossIcon } from "../../common/icons";
import QrScanner from "qr-scanner";
import { MenuItem, Select } from "@mui/material";

const QRScanner = ({ handleClose }) => {
  const [scanResult, setScanResult] = useState(null);
  const [qrScanner, setQrScanner] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (scanResult) {
      window.open(scanResult, "_blank", "noreferrer");
      handleClose();
    }
  }, [scanResult]);

  useEffect(() => {
    const scanner = new QrScanner(
      videoRef.current,
      (result) => setScanResult(result.data),
      {
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );

    setQrScanner(scanner);

    scanner.start();

    QrScanner.listCameras(true)
      .then((cams) => {
        if (cams.length === 0) {
          console.error("No cameras found.");
        } else {
          setCameras(cams);
          setSelectedCamera(cams[0]);
        }
      })
      .catch((error) => {
        console.error("Camera access error:", error);
      });

    return () => {
      scanner.stop();
    };
  }, []);

  const handleCameraChange = (event) => {
    qrScanner.setCamera(event.target.value.id);
    setSelectedCamera(event.target.value);
  };

  return (
    <div className="flex flex-col gap-5 absolute top-1/2 left-1/2 md:w-[535px] md:max-w-[535px] max-w-[400px] rounded-lg w-[calc(100vw-100px)] transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg px-4 py-3 text-[16px]">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">QR Code Scanner</h2>
        <CrossIcon className="cursor-pointer w-6 h-6" onClick={handleClose} />
      </div>
      <div className="hidden md:flex items-center gap-3">
        <p>Select Camera: </p>
        {selectedCamera && (
          <Select value={selectedCamera} onChange={handleCameraChange}>
            {cameras?.map((cam) => (
              <MenuItem key={cam.id} value={cam}>
                {cam.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
      <div className="relative">
        <video ref={videoRef} style={{ width: "100%" }} />
      </div>
    </div>
  );
};

export default QRScanner;
