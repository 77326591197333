import { useEffect, useState, useCallback } from 'react';
import { createConsumer } from '@rails/actioncable';

export const useActionCable = (channelName, params = {}) => {
  const [message, setMessage] = useState({});

  const { REACT_APP_BACKEND_WS_INITIAL, REACT_APP_BACKEND_NAME } = process.env;

  const onReceived = useCallback((message) => {
    setMessage(message.body);
  }, []);

  useEffect(() => {
    const consumer = createConsumer(`${REACT_APP_BACKEND_WS_INITIAL}${REACT_APP_BACKEND_NAME}/cable`);

    const subscription = consumer.subscriptions.create({ channel: channelName, ...params }, {
      received: onReceived,
    });

    return () => {
      subscription.unsubscribe();
      consumer.disconnect();
    };
  }, [channelName, onReceived, params?.order_id, params?.conversation_id]);

  return [message];
};
