import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUnreadCount } from './messageSlice';
import { getRequest } from '../api';

export const fetchUnreadCount = createAsyncThunk(
  'unreadMessages/fetchUnreadCount',
  async (_, { dispatch }) => {
    try {
      const response = await getRequest('/users/unread_messages_count');
      dispatch(setUnreadCount(response?.count || 0));
    } catch (error) {
      console.error('Failed to fetch unread messages count:', error);
    }
  }
);
