import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import LoginPNG from "../images/login.png";
import Logo from "../images/logo.png";
import { signUp } from "../redux/authActions";
import { addToast } from "../redux/toastSlice";
import { LOGIN } from "../constants/FrontendRoutes";
import { EyeIcon } from "../common/icons/";
import { Button } from "@mui/material";
import { postRequest } from "../api";
import { USER_ROLES_ENUM, USER_STATUS_ENUM } from "../constants";
import { validatePhoneNumber } from "../utils";

const FORM_FIELDS = {
  user_name: "",
  name: "",
  email: "",
  phone_number: "",
  password: "",
  confirm_password: "",
};

function SignupPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState(FORM_FIELDS);
  const [isFormValid, setIsFormValid] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const user = {
        user_name: formData.user_name,
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone_number,
        password: formData.password,
        role: USER_ROLES_ENUM.admin,
        status: USER_STATUS_ENUM.onboarding,
      };

      const res = await postRequest(
        `${process.env.REACT_APP_BACKEND_URL}/signup`,
        {
          user: user,
        }
      );

      if (res && res.status === 200) {
        dispatch(addToast("Successfully signed up."));
        navigate(LOGIN);
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      setFormData(FORM_FIELDS);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const email = params.get("email");

    if (email) {
      setFormData((prevData) => ({
        ...prevData,
        email,
      }));
    }
  }, [location?.search]);

  useEffect(() => {
    const isPhoneNumberValid = validatePhoneNumber(formData.phone_number);
    const passwordsMatch = formData.password === formData.confirm_password;
    const isFormComplete = Object.values(formData).every(
      (field) => field.trim() !== ""
    );

    setIsFormValid(isFormComplete && passwordsMatch && isPhoneNumberValid);
  }, [formData]);

  return (
    <div className="bg-gray-100 flex h-screen">
      <div className="w-full lg:w-3/5 xl:w-[45%] my-auto">
        <div className="flex flex-col gap-8 lg:gap-12 w-[80%] md:w-[45%] lg:w-[70%] mx-auto">
          <img alt="logo" src={Logo} className="w-11 h-auto" />
          <div className="flex flex-col gap-8">
            <div>
              <h2 className="text-stone-800 text-2xl font-bold font-['Montserrat'] leading-[33.60px]">
                Welcome to Coblr
              </h2>
              <div className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Enter the following information to create your account
              </div>
            </div>
            <form onSubmit={handleSignUp} className="flex flex-col gap-6">
              <div className="flex flex-col lg:flex-row justify-between gap-5">
                <div className="flex flex-col gap-1 w-full">
                  <label className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    User Name
                  </label>
                  <input
                    type="text"
                    name="user_name"
                    value={formData.user_name}
                    onChange={handleChange}
                    placeholder="Jane_123"
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <label className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Jane Doe"
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row justify-between gap-5">
                <div className="flex flex-col gap-1 w-full">
                  <label className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Example@gmail.com"
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <label className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Phone number
                  </label>

                  <InputMask
                    mask="(999) 999-9999"
                    value={formData.phone_number}
                    onChange={handleChange}
                  >
                    {() => (
                      <input
                        name="phone_number"
                        type="tel"
                        placeholder="(123) 456-7890"
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                    )}
                  </InputMask>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                  Password
                </label>

                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    <EyeIcon />
                  </button>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                  Confirm Password
                </label>

                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirm_password"
                    value={formData.confirm_password}
                    onChange={handleChange}
                    placeholder="Re-enter your password"
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    <EyeIcon />
                  </button>
                </div>
                {formData.password !== formData.confirm_password && (
                  <p className="text-red-400 text-xs font-normal font-['Questrial'] mt-2">
                    Password mismatch!
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!isFormValid}
                >
                  Create
                </Button>
                <div className="text-center">
                  <span className="text-[#272523] text-xs font-normal font-['Questrial'] leading-none">
                    By signing up, you agree to our{" "}
                  </span>
                  <a
                    href="https://getcoblr.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#4c8c4a] text-xs font-normal font-['Questrial'] underline leading-none"
                  >
                    Privacy Policy
                  </a>
                  <span className="text-[#272523] text-xs font-normal font-['Questrial'] leading-none">
                    {" "}
                    and{" "}
                  </span>
                  <a
                    href="https://getcoblr.com//terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#4c8c4a] text-xs font-normal font-['Questrial'] underline leading-none"
                  >
                    Terms of Service
                  </a>
                  <span className="text-[#272523] text-xs font-normal font-['Questrial'] leading-none">
                    .
                  </span>
                </div>
              </div>
            </form>
            <div className="border-[0.5px] border-b-neutral-200"></div>
          </div>
        </div>
        <span className="flex justify-center my-8 items-center gap-2">
          <div className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
            Already on Coblr?
          </div>
          <Link
            to={LOGIN}
            className="text-right text-green-700 text-xs font-normal font-['SF Pro Display'] leading-tight tracking-tight"
          >
            Log in
          </Link>
        </span>
      </div>
      <div className="hidden lg:block w-2/5 xl:w-[55%]">
        <img
          src={LoginPNG}
          alt="Coblar"
          className="w-full h-screen object-cover"
        />
      </div>
    </div>
  );
}

export default SignupPage;
