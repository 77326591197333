import { createSlice } from "@reduxjs/toolkit";
import { fetchUserData, login } from "./authActions";
import { fetchMainCategories, fetchServiceTypes } from "./storeAction";
import { fetchSubStatuses } from "./storeAction";

const storeSlice = createSlice({
  name: "store",
  initialState: {
    allServiceTypes: null,
    allMainCategories: null,
    loading: false,
    error: null,
    subStatuses: [],
    selectedSubStatuses: [],
  },
  reducers: {
    setSelectedSubStatuses: (state, action) => {
      state.selectedSubStatuses = action.payload || [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubStatuses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubStatuses.fulfilled, (state, action) => {
        state.loading = false;
        state.subStatuses = action.payload || [];
      })
      .addCase(fetchSubStatuses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch subscription statuses';
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        const store = action.payload?.stores?.[0];
        if (store) {
          state.allServiceTypes = store.service_types;
          state.allMainCategories = store.categories;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchServiceTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allServiceTypes = action.payload;
      })
      .addCase(fetchServiceTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchMainCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMainCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allMainCategories = action.payload;
      })
      .addCase(fetchMainCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        const store = action.payload?.stores?.[0];
        if (store) {
          state.allServiceTypes = store.service_types;
          state.allMainCategories = store.categories;
        }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectAllServiceType = (state) => state?.store?.allServiceTypes;
export const selectAllCategories = (state) => state?.store?.allMainCategories;
export const selectSubStatuses = state => state?.store?.subStatuses || [];
export const selectSelectedSubStatuses = state => state?.store?.selectedSubStatuses || [];

export const { setSelectedSubStatuses } = storeSlice.actions;
export default storeSlice.reducer;
