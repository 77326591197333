import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import InputMask from "react-input-mask";
import Selector from "../Form/Field/AutoComplete";
import { COUNTRIES, COMPANY_SIZE, STATES_PER_COUNTRY, ADDRESS_TYPE_ENUM } from "../../constants";
import { validateEmail, validatePhoneNumber } from "../../utils";
import { selectCurrentUser } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { postRequest, updateRequest } from "../../api";
import { fetchUserData } from "../../redux/authActions";
import { addToast } from "../../redux/toastSlice";
import { setLoading } from "../../redux/loadingSlice";

const ORGANIZATION_FIELDS = {
  email: "",
  name: "",
  phone_number: "",
  line: "",
  zipcode: "",
  state: "",
  city: "",
  country: "",
  employee_count: "",
};

const SetupCompany = ({ handleBack, handleNext, activeStep }) => {
  const userData = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [organizationData, setOrganizationData] = useState(ORGANIZATION_FIELDS);
  const [selectedCountry, setSelectedCountry] = useState(organizationData?.country || "US");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectorChange = (field) => (_, value) => {
    handleChange({ target: { name: field, value: value?.value } });
    if (field === "country") {
      setSelectedCountry(value?.value);
      setOrganizationData((prevData) => ({
        ...prevData,
        line: "",
        zipcode: "",
        state: "",
        city: "",
      }));
    }
  };


  const isSubmitEnabled = () => {
    const isPhoneNumberValid = validatePhoneNumber(
      organizationData.phone_number
    );
    const isEmailValid = validateEmail(organizationData.email);
    const addressFields = ["line", "city", "zipcode", "state", "country"];
    const areAllAddressFieldsFilled = addressFields.every(
      (field) => organizationData[field] !== ""
    );
    return (
      isEmailValid &&
      isPhoneNumberValid &&
      organizationData.name !== "" &&
      areAllAddressFieldsFilled
    );
  };

  useEffect(() => {
    if (userData?.organization) {
      const organization = userData.organization;
      const address = organization.addresses?.[0] || {};
      setSelectedCountry(address?.country || "US");
      setOrganizationData((prevData) => ({
        ...prevData,
        email: organization?.email || "",
        name: organization?.name || "",
        phone_number: organization?.phone_number || "",
        line: address?.line || "",
        city: address?.city || "",
        zipcode: address?.zipcode || "",
        state: address?.state || "",
        country: address?.country || "",
        address_id: address?.id || null,
        employee_count: organization?.employee_count || "",
      }));
    }
  }, [userData]);

  const saveOrganizationData = async () => {
    try {
      dispatch(setLoading(true));
      const isUpdate = !!userData?.organization?.id;
      const url = isUpdate
        ? `/organizations/${userData.organization.id}`
        : "/organizations";
      const requestMethod = isUpdate ? updateRequest : postRequest;
      const response = await requestMethod(url, {
        organization: {
          name: organizationData?.name,
          email: organizationData?.email,
          phone_number: organizationData?.phone_number,
          addresses_attributes: [
            {
              id: organizationData.address_id,
              line: organizationData?.line,
              city: organizationData?.city,
              zipcode: organizationData?.zipcode,
              state: organizationData?.state,
              country: organizationData?.country,
              address_type: ADDRESS_TYPE_ENUM.billing,
            },
          ],
        },
      });
      dispatch(addToast("Organization saved successfully!"));
      return response;
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const isDataChanged = () => {
    const organization = userData?.organization || {};
    const address = organization?.addresses?.[0] || {};

    return (
      organization?.email !== organizationData?.email ||
      organization?.name !== organizationData?.name ||
      organization?.phone_number !== organizationData?.phone_number ||
      address?.line !== organizationData?.line ||
      address?.city !== organizationData?.city ||
      address?.zipcode !== organizationData?.zipcode ||
      address?.state !== organizationData?.state ||
      address?.country !== organizationData?.country ||
      organization?.employee_count !== organizationData?.employee_count
    );
  };

  const handleNextClick = async () => {
    if (!isDataChanged()) {
      handleNext();
    } else {
      const response = await saveOrganizationData();
      if (response && response.status >= 200 && response.status < 300) {
        dispatch(fetchUserData({ id: userData.id }));
        handleNext();
      }
    }
  };

  return (
    <div className="flex flex-col gap-3 m-8">
      {activeStep > 0 && (
        <p className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
          Step {activeStep} / 4
        </p>
      )}
      <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Please tell us more about your business
      </p>

      <div className="flex flex-col sm:flex-row gap-4 mt-3">
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Company name
          </p>
          <TextField
            id="companyName"
            placeholder="Please enter"
            variant="outlined"
            name="name"
            value={organizationData?.name}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Company size
          </p>
          <Selector
            id="employee_count"
            name="employee_count"
            label="Please Select Company Size"
            size="large"
            options={COMPANY_SIZE}
            onChange={handleSelectorChange("employee_count")}
            value={COMPANY_SIZE.find(
              (size) => size.value === organizationData?.employee_count
            )}
          />
        </div>
      </div>
      <hr className="my-3" />
      <div className="flex flex-col sm:flex-row gap-4">
      <div className="flex flex-col gap-2 w-full basis-2/6">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Country *
          </p>
          <Selector
            name="country"
            label="Please select"
            size="large"
            options={COUNTRIES}
            onChange={handleSelectorChange("country")}
            value={COUNTRIES.find(
              (country) => country?.value === organizationData?.country
            )}
          />
        </div>
        <div className="flex flex-col gap-2 w-full basis-4/6">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Billing address *
          </p>
          <TextField
            id="line"
            placeholder="Please enter"
            variant="outlined"
            name="line"
            value={organizationData?.line}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 mt-3">
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            City *
          </p>
          <TextField
            id="city"
            placeholder="Please enter"
            variant="outlined"
            name="city"
            value={organizationData?.city}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base text-nowrap font-normal font-['Questrial'] leading-snug">
            Province/State/Region *
          </p>
          <Selector
            name="state"
            label="Please select"
            size="large"
            options={STATES_PER_COUNTRY?.[selectedCountry] || []}
            onChange={handleSelectorChange("state")}
            value={STATES_PER_COUNTRY?.[selectedCountry]?.find(
              (state) => state?.value === organizationData?.state
            )}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          Zip/Postal Code *
          </p>
          <InputMask
            mask={selectedCountry === 'CA' ? 'a9a 9a9' : '99999'}
            value={organizationData?.zipcode}
            onChange={handleChange}
          >
            {() => (
              <TextField
                type="text"
                name="zipcode"
                placeholder="12345"
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>
      <hr className="my-3" />
      <div className="flex flex-col gap-2">
        <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          Contact email
        </p>
        <p className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
          *Please include the email you want to use for customers to contact your business.
        </p>
        <TextField
          id="contactEmail"
          placeholder="Please enter"
          variant="outlined"
          name="email"
          value={organizationData?.email}
          onChange={handleChange}
        />
      </div>

      <div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Phone number *
          </p>
          <InputMask
            mask="(999) 999-9999"
            value={organizationData?.phone_number}
            onChange={handleChange}
          >
            {() => (
              <TextField
                id="phone_number"
                name="phone_number"
                type="tel"
                placeholder="(123) 456-7890"
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 mt-4 ml-auto w-[90%]">
        <div></div>
        <Button
          variant="outlinedSecondary"
          className="w-full"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="containedPrimary"
          className="w-full"
          disabled={!isSubmitEnabled()}
          onClick={handleNextClick}
        >
          Save & Next
        </Button>
      </div>
    </div>
  );
};

export default SetupCompany;
