import { FilterList, Search } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getRequest, updateRequest } from "../api";
import { EditIcon, FlagIcon } from "../common/icons";
import MenuOptionButton from "../components/Button/MenuOptionButton";
import EntityPage from "../components/EntityPage";
import AutoCompleteInput from "../components/Form/Field/AutoComplete";
import SearchInput from "../components/Form/Field/SearchInput";
import FieldItem from "../components/Form/FieldItem";
import MultiSelectDropdown from "../components/Form/MultiSelectDropdown";
import BulkList from "../components/Message/BulkList";
import MessageDetail from "../components/Message/Detail";
import MessageList from "../components/Message/List";
import MessageType from "../components/Message/Type";
import {
  CONVERSATION_STATUS,
  CONVERSATION_TYPE,
  CONVERSATION_TYPE_KEYS,
  CONVERSATION_TYPE_VALUES,
  ORDER_CHAT_TYPE_FILTERS,
} from "../constants";
import { STORES } from "../constants/BackendRoutes";
import { CONVERSATIONS } from "../constants/FrontendRoutes";
import { useDebounce } from "../customHooks/useDebounce";
import useHandleDraftMessage from "../customHooks/useHandleDraftMessage";
import { selectCurrentUser } from "../redux/authSlice";
import { setLoading } from "../redux/loadingSlice";
import { addToast } from "../redux/toastSlice";

const iconButtonStyles = {
  "&:hover": {
    bgcolor: "#DCFCE7",
    color: "#16a34a",
  },
};
const iconsStyles =
  "hover:bg-green-100/70 hover:text-green-600 rounded-lg w-7 h-7";

const Messages = () => {
  const { id } = useParams();
  const conversationId = parseInt(id);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const printRef = useRef();
  const userData = useSelector(selectCurrentUser);

  const storeId = userData?.stores?.[0]?.id;

  const [searchInput, setSearchInput] = useState("");
  const [selectedListItem, setSelectedListItem] = useState(
    CONVERSATION_TYPE_KEYS.INBOX
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(true);
  const [createNewMessage, setCreateNewMessage] = useState(false);
  const [isAllMessagesSelected, setIsAllMessagesSelected] = useState(true);
  const [isUnreadMessagesSelected, setIsUnreadMessagesSelected] =
    useState(false);
  const [isAllSelectedInDropdown, setIsAllSelectedInDropdown] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState({});
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedOrderForMessage, setSelectedOrderForMessage] = useState(null);

  const debouncedSearchValue = useDebounce(searchInput);
  const handleDraftMessage = useHandleDraftMessage();

  const toggleSelection = (setState) => setState((prev) => !prev);
  const handleAllMessagesToggle = () =>
    toggleSelection(setIsAllMessagesSelected);
  const handleUnreadMessagesToggle = () =>
    toggleSelection(setIsUnreadMessagesSelected);
  const handleFilterMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleFilterMenuClose = () => setAnchorEl(null);
  const handlePrint = useReactToPrint({ content: () => printRef.current });

  const updateMessageStatus = async (status, message) => {
    try {
      dispatch(setLoading(true));
      await updateRequest(`${STORES}/${storeId}/conversations/${id}`, {
        chat_status: status,
      });
      dispatch(addToast(message));
      fetchConversations(selectedListItem);
      setIsDetailVisible(false);
      navigate(CONVERSATIONS);
    } catch (err) {
      setIsDetailVisible(true);
      dispatch(addToast(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const generateButtonData = () => {
    const commonButtons = [
      {
        name: "Delete",
        function: () =>
          updateMessageStatus(
            CONVERSATION_STATUS.TRASH,
            "Conversation moved to Trash."
          ),
        disabled: false,
      },
      { name: "Print", function: handlePrint, disabled: false },
    ];
    return selectedListItem === CONVERSATION_TYPE.TRASH.key
      ? [
          ...commonButtons.filter((button) => button.name !== "Delete"),
          {
            name: "Move to Inbox",
            function: () =>
              updateMessageStatus(
                CONVERSATION_STATUS.ACTIVE,
                "Conversation moved to Inbox."
              ),
            disabled: false,
          },
        ]
      : commonButtons;
  };

  const fetchConversations = async (selectedListItem = CONVERSATION_TYPE_KEYS.INBOX) => {
    try {
      const data = await fetchAndMapConversations(selectedListItem);
      setConversations(data);
      setSelectedConversation(data?.find((c) => c?.id === conversationId));
      return data;
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
  };

  const fetchAndMapConversations = async () => {
    try {
      const selectedChatStatus = ORDER_CHAT_TYPE_FILTERS.find(
        (item) => item.label === selectedListItem
      )?.value;
      const conversationsData = await getRequest(
        `${STORES}/${storeId}/conversations`,
        {
          filters: {
            chat_status: selectedChatStatus || CONVERSATION_TYPE_VALUES.ACTIVE,
          },
        },
        "customer,store&fields=user.id,user.name,order.id,store.id"
      );

      return conversationsData.map((conversation) => ({
        id: conversation?.id,
        chatStatus: conversation?.chat_status,
        displayName: conversation?.customer?.name,
        customerId: conversation?.customer?.id,
        message: conversation?.last_message?.content,
        messageId: conversation?.last_message?.id,
        time: conversation?.last_message?.created_at,
        highlighted:
          conversation?.last_message?.user_id === conversation?.customer?.id &&
          !conversation?.last_message?.is_read,
        orderIds: conversation?.store_order_ids,
        store: { id: conversation?.store?.id },
      }));
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
  };

  useEffect(() => handleDraftMessage(), [selectedListItem]);
  useEffect(() => {
    if (userData && !createNewMessage) fetchConversations(selectedListItem);
  }, [userData, selectedListItem, createNewMessage]);
  useEffect(() => {
    setOrders(selectedConversation?.orderIds || []);
    setSelectedOrders([]);
  }, [selectedConversation]);
  useEffect(() => setSelectedOrderForMessage(null), [selectedRecipient]);

  const findConversationByCusID = (cusID) =>
    conversations?.find((c) => c.customerId === cusID);

  const orderOptions = findConversationByCusID(
    selectedRecipient?.id
  )?.orderIds?.map((id) => ({
    title: `#${id}`,
    value: `${id}`,
  }));

  const buttonData = generateButtonData();

  return (
    <div className="bg-[#F8F8F8] overflow-hidden">
      <EntityPage
        title="Messages"
        classNameLayout="hidden md:flex p-0 md:p-5"
        childrenLayout="p-0"
      >
        <div
          className={`grid sm:border-t border-[#F3F0E8] grid-cols-5 grid-rows-auto`}
        >
          <div
            className={`items-center flex flex-row justify-between px-4 p-2 py-4 sm:p-4 col-span-5 sm:col-span-2 ${
              (id || createNewMessage) && "hidden sm:flex"
            }`}
          >
            <div className="text-[#272523] text-xs sm:text-lg font-semibold font-['Montserrat']">
              All Messages
            </div>
            <div className="flex items-center" style={{ color: "#939291" }}>
              {!isSearchEnabled ? (
                <IconButton
                  onClick={() => setIsSearchEnabled(true)}
                  size="small"
                  sx={iconButtonStyles}
                >
                  <Search className={iconsStyles} />
                </IconButton>
              ) : (
                <SearchInput
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder="Search"
                  isOnClearEnabled={true}
                  onClear={() => {
                    setIsSearchEnabled(false);
                    setSearchInput("");
                  }}
                  width="200px"
                />
              )}
              <IconButton
                onClick={() => {
                  setCreateNewMessage(true);
                  setIsDetailVisible(true);
                  setSelectedOrderForMessage(null);
                }}
                size="small"
                sx={iconButtonStyles}
              >
                <EditIcon
                  className={iconsStyles}
                  fill={createNewMessage ? "green" : "gray"}
                />
              </IconButton>

              <IconButton
                onClick={handleFilterMenuClick}
                size="small"
                sx={iconButtonStyles}
              >
                <FilterList className={iconsStyles} />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleFilterMenuClose}
              >
                <MenuItem onClick={handleAllMessagesToggle}>
                  <Checkbox checked={isAllMessagesSelected} />
                  <ListItemText primary="All Messages" />
                </MenuItem>
                <MenuItem onClick={handleUnreadMessagesToggle}>
                  <Checkbox checked={isUnreadMessagesSelected} />
                  <ListItemText primary="Unread Messages" />
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className="flex flex-col sm:hidden col-span-5">
            {isDetailVisible && (
              <div className="bg-transparent flex flex-row py-4">
                {(createNewMessage || id) && (
                  <div className="basis-[10%] flex justify-center items-center">
                    <Link
                      to={CONVERSATIONS}
                      onClick={() => setCreateNewMessage(false)}
                    >
                      <ChevronLeftIcon className="text-gray-600" />
                    </Link>
                  </div>
                )}

                {!createNewMessage && id && (
                  <div className="basis-[70%] flex flex-col">
                    <div className="text-gray-500 text-xs">Order# {id}</div>
                    <div className="text-base">
                      {selectedConversation?.displayName}
                    </div>
                  </div>
                )}

                {createNewMessage && (
                  <React.Fragment>
                    <p
                      className="font-['Montserrat'] font-normal text-base text-[#272523] flex pr-1 text-nowrap"
                      style={{ lineHeight: "1.5em" }}
                    >
                      Send to:
                    </p>
                    <BulkList
                      userData={userData}
                      setSelectedRecipient={setSelectedRecipient}
                    />
                  </React.Fragment>
                )}

                {!createNewMessage && id && (
                  <div className="basis-[20%] flex justify-center gap-2 items-center">
                    <IconButton
                      onClick={() =>
                        updateMessageStatus(
                          selectedConversation?.chatStatus ===
                            CONVERSATION_STATUS.FLAG
                            ? CONVERSATION_STATUS.ACTIVE
                            : CONVERSATION_STATUS.FLAG,
                          selectedConversation?.chatStatus ===
                            CONVERSATION_STATUS.FLAG
                            ? "Conversation moved to Inbox."
                            : "Conversation marked as flagged."
                        )
                      }
                    >
                      <FlagIcon
                        className="w-7 h-7"
                        fill={
                          selectedConversation?.chatStatus ===
                          CONVERSATION_STATUS.FLAG
                            ? "green"
                            : "gray"
                        }
                      />
                    </IconButton>
                    <MenuOptionButton
                      data={buttonData}
                      icon={<MoreVertIcon />}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="sm:flex hidden rounded-tl-lg rounded-tr-lg border-[#F3F0E8] p-4 col-span-3 bg-white">
            <div className="flex flex-col w-full">
              {!createNewMessage ? (
                isDetailVisible &&
                id && (
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <span className="text-[#272523] text-lg font-semibold font-['Montserrat']">
                        {selectedConversation?.displayName}
                      </span>
                      <MultiSelectDropdown
                        options={orders}
                        selectedValues={selectedOrders}
                        onSelectionChange={setSelectedOrders}
                        callbackFunction={setIsAllSelectedInDropdown}
                        searchTerm={searchTerm}
                        onSearchChange={(e) => setSearchTerm(e.target.value)}
                        enableSearch={true}
                        placeholder="Select orders"
                        allItemsLabel="All messages"
                        itemLabel={(item) => `${item}`}
                      />
                    </div>
                    <div className="flex gap-2 items-center">
                      <IconButton
                        onClick={() =>
                          updateMessageStatus(
                            selectedConversation?.chatStatus ===
                              CONVERSATION_STATUS.FLAG
                              ? CONVERSATION_STATUS.ACTIVE
                              : CONVERSATION_STATUS.FLAG,
                            selectedConversation?.chatStatus ===
                              CONVERSATION_STATUS.FLAG
                              ? "Conversation moved to Inbox."
                              : "Conversation marked as flagged."
                          )
                        }
                      >
                        <FlagIcon
                          className="w-7 h-7"
                          fill={
                            selectedConversation?.chatStatus ===
                            CONVERSATION_STATUS.FLAG
                              ? "green"
                              : "gray"
                          }
                        />
                      </IconButton>
                      <MenuOptionButton
                        data={buttonData}
                        icon={<MoreVertIcon />}
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="flex flex-row justify-between items-center h-full">
                  <div className="flex gap-2">
                    <p
                      className="font-['Montserrat'] items-center font-normal text-base text-[#272523] flex pr-1 text-nowrap"
                      style={{ lineHeight: "1.5em" }}
                    >
                      Send to:
                    </p>
                    <BulkList
                      conversations={conversations}
                      storeId={storeId}
                      userData={userData}
                      setSelectedRecipient={setSelectedRecipient}
                    />
                  </div>
                  <Box>
                    <FieldItem>
                      <Typography width="30%" variant="body">
                        Order #
                      </Typography>
                      <AutoCompleteInput
                        name="order"
                        label="Optional"
                        options={orderOptions}
                        value={selectedOrderForMessage}
                        onChange={(_e, value) =>
                          setSelectedOrderForMessage(value)
                        }
                      />
                    </FieldItem>
                  </Box>
                </div>
              )}
            </div>
          </div>

          <Box
            className={`flex flex-col sm:flex-row justify-between md:border-t border-[#F3F0E8] sm:ps-6 col-span-5 sm:col-span-2 bg-white md:bg-transparent ${
              (id || createNewMessage) && "hidden sm:flex"
            }`}
          >
            <MessageType
              storeId={storeId}
              selectedListItem={selectedListItem}
              fetchConversations={fetchConversations}
              setIsDetailVisible={setIsDetailVisible}
              setSelectedListItem={setSelectedListItem}
              setCreateNewMessage={setCreateNewMessage}
            />
            <Box
              sx={{ height: `calc(100vh - 170px)` }}
              className="border-l border-r border-[#F3F0E8] overflow-auto flex flex-col my-2 sm:my-0 gap-2 sm:w-3/5"
            >
              <MessageList
                storeId={storeId}
                userData={userData}
                setCreateNewMessage={setCreateNewMessage}
                conversationsData={conversations}
                searchInput={debouncedSearchValue}
                selectedListItem={selectedListItem}
                isAllMessagesSelected={isAllMessagesSelected}
                isUnreadMessagesSelected={isUnreadMessagesSelected}
                setIsDetailVisible={setIsDetailVisible}
                setSelectedConversation={setSelectedConversation}
              />
            </Box>
          </Box>

          <React.Fragment>
            <Box
              sx={{
                height: `calc(100vh - 170px)`,
              }}
              className="sm:block overflow-auto border-t border-[#F3F0E8] p-4 col-span-5 sm:col-span-3 bg-white"
            >
              {isDetailVisible && (
                <MessageDetail
                  ref={printRef}
                  defaultStoreId={storeId}
                  userData={userData}
                  orders={orders}
                  createNewMessage={createNewMessage}
                  selectedRecipient={selectedRecipient}
                  selectedOrderForMessage={selectedOrderForMessage}
                  selectedOrders={selectedOrders}
                  conversationsData={conversations}
                  selectedConversation={selectedConversation}
                  isAllSelectedInDropdown={isAllSelectedInDropdown}
                  setSelectedOrderForMessage={setSelectedOrderForMessage}
                  fetchConversations={fetchConversations}
                  setIsDetailVisible={setIsDetailVisible}
                  setCreateNewMessage={setCreateNewMessage}
                  setSelectedConversation={setSelectedConversation}
                />
              )}
            </Box>
          </React.Fragment>
        </div>
      </EntityPage>
    </div>
  );
};

export default Messages;
