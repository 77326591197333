import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import { getRequest, postRequest } from "../api";

import { Plus, UploadIcon } from "../common/icons";

import EntityPage from "../components/EntityPage";
import FileUploadModal from "../components/Modal/FileUploadModal";
import CustomTable from "../components/Table";

import { CUSTOMER_DETAILS, CUSTOMERS_NEW } from "../constants/FrontendRoutes";

import { selectCurrentUser } from "../redux/authSlice";
import { addToast } from "../redux/toastSlice";
import { setLoading, updateProgressBar } from "../redux/loadingSlice";

import { downloadCSV, processCSVData } from "../utils/customerList";
import { USER_ROLES } from "../constants";
import CustomPagination from "../components/CustomPagination";

const TABLE_COLUMNS = [
  { field: "id", label: "ID", sortable: true, width: "100px" },
  {
    field: "name",
    label: "Name",
    sortable: true,
    searchable: true,
    width: "100px",
  },
  { field: "email", label: "Email", searchable: true, width: "150px" },
  {
    field: "phone_number",
    label: "Phone Number",
    searchable: true,
    width: "150px",
  },
  {
    field: "number_of_orders",
    label: "# of orders",
    sortable: true,
    width: "100px",
  },
  {
    field: "amount_spent",
    label: "Amount Spent",
    sortable: true,
    width: "100px",
  },
  {
    field: "signup_date",
    label: "Sign Up Date",
    sortable: true,
    width: "100px",
  },
];

const transformData = (data) =>
  data?.map((customer) => ({
    id: customer.id,
    name: customer.name,
    email: customer.email,
    phone_number: customer.phone_number,
    number_of_orders: customer.orders?.filter(
      (order) => order.status !== "quote"
    ).length,
    amount_spent: `$${customer.orders
      ?.filter((order) => order.status !== "quote")
      ?.reduce((sum, order) => sum + order.net_total, 0)
      ?.toFixed(2)}`,
    signup_date: new Date(
      customer.last_sign_up ? customer.last_sign_up : customer.created_at
    ).toLocaleDateString(),
    link: CUSTOMER_DETAILS.replace(":id", customer.id),
  }));

const CustomerList = () => {
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectCurrentUser);

  const [paginationMeta, setPaginationMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    if (userData) fetchStoreCustomers();
  }, [userData, currentPage, perPage]);

  const fetchStoreCustomers = async () => {
    try {
      dispatch(setLoading(true));
      const { data, meta: { pagination } } = await getRequest(
        `stores/${userData.stores[0].id}/users`,
        {
          filters: { role: [USER_ROLES.CUSTOMER] },
          page: currentPage,
          per_page: perPage,
        },
        "orders&fields=user.id,user.name,user.email,user.phone_number,user.last_sign_up,user.created_at,order.status,order.net_total"
      );

      if (data?.length > 0) {
        setPaginationMeta(pagination);
        setRows(transformData(data));
      } else {
        dispatch(addToast("Customers not Found", { appearance: "error" }));
      }
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`, { appearance: "error" }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      dispatch(setLoading(true));
      dispatch(updateProgressBar({ display: true, value: 0 }));

      const csvData = e.target.result;
      const customers = processCSVData(csvData);

      const batches = [];
      const batchSize = 50;
      const totalBatches = Math.ceil(customers?.length / batchSize);
      const allFailedUsers = [];
      let processedBatches = 0;

      for (let i = 0; i < customers?.length; i += batchSize) {
        batches.push(customers.slice(i, i + batchSize));
      }

      try {
        for (const batch of batches) {
          const { data: { failed_users } } = await postRequest(
            `stores/${userData.stores[0].id}/users/batch_create`,
            { users: batch }
          );

          allFailedUsers.push(...failed_users);

          processedBatches++;
          const progressValue = Math.round((processedBatches / totalBatches) * 100);
          dispatch(updateProgressBar({ display: true, value: progressValue }));
        }

        dispatch(
          updateProgressBar({
            successMessage: `${
              customers.length - allFailedUsers.length
            } out of ${customers.length} User(s) successfully created. `,
          })
        );
      } catch (error) {
        dispatch(
          addToast(`Error adding customers: ${error?.message || error}`, {
            appearance: "error",
          })
        );
      } finally {
        if (allFailedUsers?.length > 0) {
          const csvHeader = "Full Name,Email,Phone Number,Errors\n";
          const csvContent = allFailedUsers
            .map((user) => {
              const errors = user.errors.join(" | ");
              return `${user?.name || ""},${user?.email || ""},${
                user?.phone_number || ""
              },"${errors}"`;
            })
            .join("\n");

          const csvString = csvHeader + csvContent;
          downloadCSV(csvString, 'conflict_report.csv');
        }
      }
    };

    reader.readAsText(file);
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <EntityPage title="Customer List" breadcrumbs>
      <div className="flex flex-col justify-between">
        <CustomTable
          columns={TABLE_COLUMNS}
          rows={rows}
          sortable
          searchable
          actions={
            <>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<UploadIcon />}
                sx={{
                  color: "black",
                  font: "Questrial",
                  fontSize: "1rem",
                  borderRadius: "0.5rem",
                  borderColor: "rgb(214, 211, 209)",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "black",
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleOpenModal}
              >
                Import Data
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Plus />}
                onClick={() => navigate(CUSTOMERS_NEW)}
              >
                Add Customer
              </Button>
            </>
          }
        />
        <CustomPagination
          paginationMeta={paginationMeta}
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <FileUploadModal
        open={modalOpen}
        onClose={handleCloseModal}
        onFileUpload={handleFileUpload}
      />
    </EntityPage>
  );
};

export default CustomerList;
