import React, { useState, useEffect } from "react";
import { Modal, Button } from "@mui/material";
import { selectCurrentUser } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import { updateRequest, postRequest } from "../../api";
import { fetchUserData } from "../../redux/authActions";
import { formatDate } from "../../utils";
import { ADDRESS_TYPE_ENUM } from "../../constants";

const INITIAL_ADDRESS = {
  country: "",
  zipcode: "",
  line: "",
  state: "",
  city: "",
};

const Address = ({ setAddressDetailChanges }) => {
  const userData = useSelector(selectCurrentUser);
  const [addresses, setAddresses] = useState([]);
  const dispatch = useDispatch();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(INITIAL_ADDRESS);
  const[isBtnDisabled,setIsBtnDisabled]=useState(true);

  useEffect(() => {
    if (userData?.id) {
      dispatch(fetchUserData({ id: userData.id, login_type: "customer" }));
    }
  }, [dispatch, userData?.id]);

  useEffect(() => {
    if (userData?.addresses) {
      setAddresses(userData.addresses);
    }
  }, [userData?.addresses]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = {
      ...formValues,
      [name]:value,
    };
    setFormValues(updatedValues);
    setIsBtnDisabled(!checkIfAllFieldsFilled(updatedValues));
  };

  const checkIfAllFieldsFilled=(values)=>{
    return Object.values(values).every(value =>
      typeof value === "string" ? value.trim() !== "" : value != ""
    );
  };

  const handleAddModalOpen = () => {
    setFormValues(INITIAL_ADDRESS);
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => setIsAddModalOpen(false);

  const handleEditModalOpen = (address) => {
    setFormValues({
      id: address?.id,
      country: address?.country || "",
      zipcode: address?.zipcode || "",
      line: address?.line || "",
      state: address?.state || "",
      city: address?.city || "",
    });
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => setIsEditModalOpen(false);
  const handleSubmit = async () => {
    const updatedAddress = { ...formValues, address_type: ADDRESS_TYPE_ENUM.customer };
    updatedAddress.updated_at = new Date().toISOString();

    try {
      if (isEditModalOpen) {
        const addressId = updatedAddress.id;
        await updateRequest(
          `stores/${userData?.stores_as_customer?.[0]?.store?.id}/users/${userData?.id}/addresses/${addressId}`,
          {
            address: updatedAddress,
          }
        );
        setAddresses((prevAddresses) =>
          prevAddresses.map((addr) =>
            addr.id === addressId ? updatedAddress : addr
          )
        );
        setAddressDetailChanges((prevChanges) => ({
          ...prevChanges,
          edit: updatedAddress,
        }));
      } else {
        const response = await postRequest(
          `stores/${userData?.stores_as_customer?.[0]?.store?.id}/users/${userData?.id}/addresses`,
          {
            address: updatedAddress,
          }
        );
        const newAddress = response.data;
        setAddresses((prevAddresses) => [...prevAddresses, newAddress]);

        setAddressDetailChanges((prevChanges) => ({
          ...prevChanges,
          add: newAddress,
        }));
      }
      dispatch(
        addToast(
          `Address ${isEditModalOpen ? "updated" : "added"} successfully!`
        )
      );

      if (isEditModalOpen) {
        handleEditModalClose();
      } else {
        handleAddModalClose();
      }
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`));
    }
  };

  return (
    <div className="w-full h-auto p-[32px] px-[24px] gap-[10px] rounded-tl-[8px] bg-white my-10">
      <div className="w-full h-auto gap-[16px]">
        <div className="w-full h-[34px] gap-0 flex justify-between">
          <h2 className="w-[76px] h-[25px] gap-[8px] font-montserrat text-[18px] font-semibold leading-[25.2px] text-left">
            Address
          </h2>
          <Button
            onClick={handleAddModalOpen}
            className="w-[198px] h-[34px] p-[6px] px-[16px] gap-[8px] rounded-tl-[8px] text-[#4C8C4A]"
            variant="text"
          >
            + Add a new address
          </Button>
        </div>
        <div className="w-full gap-0 rounded-tl-[8px] border-[2px] border-solid border-[#C9C8C8] my-3 p-4">
          <div className="w-full h-[51px] flex space-between">
            <span className="w-full h-[50px] p-[20px] pt-[21px] px-[16px] gap-[10px]">
              <span className="font-questrial text-xs font-normal leading-4 text-left h-11 w-4">
                Address
              </span>
            </span>
            <span className="w-full h-[50px] p-[17px] px-[20px] gap-[12px]">
              <span className="font-questrial text-xs font-normal leading-4 text-left w-12 h-4">
                Edit date
              </span>
            </span>
          </div>

          {addresses?.length === 0 ? (
            <div className="text-gray-500 text-center">
              No addresses available.
            </div>
          ) : (
            addresses?.map((address, index) => (
              <React.Fragment key={index}>
                <div className="w-full h-[50px] flex space-between">
                  <div className="w-full h-[50px] p-[20px] pt-[21px] px-[16px] gap-[10px] overflow-hidden">
                    <p className="text-sm break-words truncate">{`${address.line}`}</p>
                  </div>
                  <div className="w-full h-[50px] p-[17px] px-[20px] gap-[12px] flex items-center justify-between">
                    <span className="text-gray-500 text-[12px] leading-[16.8px]">
                      {formatDate(address.updated_at)}
                    </span>
                    <Button
                      className="text-[#4C8C4A]"
                      variant="text"
                      onClick={() => handleEditModalOpen(address)}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
                {index < addresses?.length - 1 && (
                  <hr className="border-[#C9C8C8] my-2" />
                )}
              </React.Fragment>
            ))
          )}
        </div>
      </div>
      <Modal
        open={isAddModalOpen}
        onClose={handleAddModalClose}
        className="fixed inset-0 flex items-center justify-center z-50"
      >
        <div className="w-full max-w-[584px] h-auto max-h-[90%] p-8 rounded-lg shadow-lg bg-white overflow-auto">
          <div>
            <div className="w-full max-w-[520px] text-[18px] font-semibold leading-[25.2px] text-left text-[#272523] mb-5">
              Add new address
            </div>
            <div className="w-full max-w-[520px]">
              <div className="flex flex-col gap-4 md:flex-row md:gap-5">
                <div className="flex-1">
                  <label className="font-questrial text-[16px] font-normal leading-[22.4px] text-left">
                    Country
                  </label>
                  <input
                    className="w-full h-[48px] p-[13px] px-[16px] mt-3 border border-[#DFDFDF]"
                    name="country"
                    value={formValues.country}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex-1">
                  <label className="font-questrial text-[16px] font-normal leading-[22.4px] text-left">
                    Zip Code
                  </label>
                  <input
                    className="w-full h-[48px] p-[13px] px-[16px] mt-3 border border-[#DFDFDF]"
                    name="zipcode"
                    value={formValues.zipcode}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full my-5">
                <label className="font-questrial text-[16px] font-normal leading-[22.4px] text-left text-[#272523]">
                  Address
                </label>
                <input
                  className="w-full h-[48px] p-[13px] px-[16px] mt-3 border border-[#DFDFDF]"
                  name="line"
                  value={formValues.line}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col gap-4 md:flex-row md:gap-5 my-5">
                <div className="flex-1">
                  <label className="font-questrial text-[16px] font-normal leading-[22.4px] text-left">
                    State
                  </label>
                  <input
                    className="w-full h-[48px] p-[13px] px-[16px] mt-3 border border-[#DFDFDF]"
                    name="state"
                    value={formValues.state}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex-1">
                  <label className="font-questrial text-[16px] font-normal leading-[22.4px] text-left">
                    City
                  </label>
                  <input
                    className="w-full h-[48px] p-[13px] px-[16px] mt-3 border border-[#DFDFDF]"
                    name="city"
                    value={formValues.city}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-6">
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={isBtnDisabled}
              >
                Add Address
              </Button>
              <Button variant="text" onClick={handleAddModalClose}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={isEditModalOpen}
        onClose={handleEditModalClose}
        className="fixed inset-0 flex items-center justify-center z-50"
      >
        <div className="w-full max-w-[584px] h-auto max-h-[90%] p-8 rounded-lg shadow-lg bg-white overflow-auto">
          <div>
            <div className="w-full max-w-[520px] text-[18px] font-semibold leading-[25.2px] text-left text-[#272523] mb-5">
              Edit Address
            </div>
            <div className="w-full max-w-[520px]">
              <div className="flex flex-col gap-4 md:flex-row md:gap-5">
                <div className="flex-1">
                  <label className="font-questrial text-[16px] font-normal leading-[22.4px] text-left">
                    Country
                  </label>
                  <input
                    className="w-full h-[48px] p-[13px] px-[16px] mt-3 border border-[#DFDFDF]"
                    name="country"
                    value={formValues.country}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex-1">
                  <label className="font-questrial text-[16px] font-normal leading-[22.4px] text-left">
                    Zip Code
                  </label>
                  <input
                    className="w-full h-[48px] p-[13px] px-[16px] mt-3 border border-[#DFDFDF]"
                    name="zipcode"
                    value={formValues.zipcode}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full my-5">
                <label className="font-questrial text-[16px] font-normal leading-[22.4px] text-left text-[#272523]">
                  Address
                </label>
                <input
                  className="w-full h-[48px] p-[13px] px-[16px] mt-3 border border-[#DFDFDF]"
                  name="line"
                  value={formValues.line}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col gap-4 md:flex-row md:gap-5 my-5">
                <div className="flex-1">
                  <label className="font-questrial text-[16px] font-normal leading-[22.4px] text-left">
                    State
                  </label>
                  <input
                    className="w-full h-[48px] p-[13px] px-[16px] mt-3 border border-[#DFDFDF]"
                    name="state"
                    value={formValues.state}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex-1">
                  <label className="font-questrial text-[16px] font-normal leading-[22.4px] text-left">
                    City
                  </label>
                  <input
                    className="w-full h-[48px] p-[13px] px-[16px] mt-3 border border-[#DFDFDF]"
                    name="city"
                    value={formValues.city}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-6">
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={isBtnDisabled}
              >
                Save Changes
              </Button>
              <Button variant="text" onClick={handleEditModalClose}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Address;
