import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRequest, postRequest } from "../../api";
import { initialStatuses, OrderStatusMap, statusColors } from "../../constants/OrderStatus";
import { selectCurrentUser } from "../../redux/authSlice";
import { setLoading } from "../../redux/loadingSlice";
import { fetchSubStatuses } from "../../redux/storeAction";
import { selectSubStatuses } from "../../redux/storeSlice";
import { addToast } from "../../redux/toastSlice";
import Capsule from "../Capsule";
import ConfirmationModal from "../Modal/ConfirmationModal";

const SubStatusLabel = () => {
  const currentUser = useSelector(selectCurrentUser);
  const subStatuses = useSelector(selectSubStatuses);

  const dispatch = useDispatch();
  const inputRefs = useRef([]);

  const [openModal, setOpenModal] = useState(false);
  const [subStatusToDelete, setSubStatusToDelete] = useState(null);

  const [statuses, setStatuses] = useState(initialStatuses);

  const addSubStatus = async (index) => {
    const input = inputRefs?.current[index];
    const value = input?.value?.trim();

    if (value) {
      try {
        dispatch(setLoading(true));
        const response = await postRequest(
          `stores/${currentUser?.stores[0]?.id}/sub_statuses`,
          {
            sub_status: {
              name: value,
              order_status: statuses[index].status,
            },
          }
        );
        if (response?.data?.id) {
          const newStatuses = [...statuses];
          newStatuses[index]?.subStatuses?.push(response?.data);
          setStatuses(newStatuses);
          dispatch(fetchSubStatuses(currentUser?.stores[0]?.id));
          dispatch(addToast("Sub-status Added Successfully"));
          input.value = "";
        }
      } catch (error) {
        dispatch(addToast("Something went wrong!"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const deleteSubStatus = async (subStatus) => {
    try {
      dispatch(setLoading(true));
      const response = await deleteRequest(
        `${`stores/${currentUser?.stores[0]?.id}/sub_statuses`}/${
          subStatus?.id
        }`
      );
      if (response?.success) {
        const newStatuses = statuses?.map((status) => ({
          ...status,
          subStatuses: status.subStatuses.filter((s) => s.id !== subStatus.id),
        }));
        setStatuses(newStatuses);
        dispatch(fetchSubStatuses(currentUser?.stores[0]?.id));
        dispatch(addToast("Sub-status Deleted Successfully"));
      }
    } catch (error) {
      dispatch(addToast("Something went wrong!"));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const showDeleteModal = (subStatus) => {
    setSubStatusToDelete(subStatus);
    setOpenModal(true);
  };

  const confirmDeletion = async () => {
    if (subStatusToDelete) {
      await deleteSubStatus(subStatusToDelete);
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(currentUser?.stores) && !isEmpty(subStatuses)) {
      const updatedStatuses = statuses?.map((s) => {
        const filteredSubStatuses = subStatuses?.filter(
          (subStatus) => subStatus?.order_status === s.status
        );
        return {
          ...s,
          subStatuses: filteredSubStatuses,
        };
      });
      setStatuses(updatedStatuses);
    }
  }, [subStatuses]);

  useEffect(() => {
    if (!isEmpty(currentUser?.stores)) {
      dispatch(fetchSubStatuses(currentUser?.stores?.[0]?.id));
    }
  }, []);

  return (
    <div className="border rounded-lg m-4 bg-white mt-5 flex flex-col max-h-[500px] overflow-auto no-scrollbar">
      <div className="sm:flex hidden px-4 py-2 border-b">
        <Typography className="min-w-[25%] text-stone-800 text-base font-normal font-['Questrial']">
          Status
        </Typography>
        <Typography className="min-w-[75%] text-stone-800 text-base font-normal font-['Questrial']">
          Sub-status
        </Typography>
      </div>
      {statuses?.map((statusObj, index) => (
        <div
          key={index}
          className={`flex sm:flex-row flex-col items-center w-full py-4 px-3 ${
            index !== statuses.length - 1 ? "border-b" : ""
          }`}
        >
          <div className="sm:min-w-[25%] w-full">
            <span
              className="text-[#BF975F] text-base font-normal font-['Questrial'] px-3 py-2 mr-2 rounded-full"
              style={{
                backgroundColor: statusColors[statusObj.status]?.background,
                color: statusColors[statusObj.status]?.text,
              }}
            >
              {OrderStatusMap[statusObj.status]}
            </span>
          </div>
          <div className="sm:min-w-[75%] mt-2 w-full flex flex-col">
            <div className="flex items-center">
              <TextField
                sx={{
                  maxHeight: "70px",
                  overflowY: "auto",
                }}
                size="small"
                placeholder="Please enter"
                multiline
                variant="outlined"
                fullWidth
                inputRef={(el) => (inputRefs.current[index] = el)}
              />
              <Typography className="text-[#939291]">
                <button
                  onClick={() => addSubStatus(index)}
                  className={`bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center mx-2 text-gray-500 ${
                    statuses[index]?.subStatuses?.length >= 10
                      ? "bg-gray-300 cursor-not-allowed"
                      : "hover:bg-[#4C8C4A] hover:text-white"
                  }`}
                  disabled={statuses[index]?.subStatuses?.length >= 10}
                >
                  <AddIcon fontSize="small" />
                </button>
              </Typography>
            </div>
           {statusObj?.subStatuses?.length > 0 && (
             <div className="flex overflow-x-auto no-scrollbar space-x-2 max-h-24 whitespace-nowrap rounded-full mt-2">
             {statusObj?.subStatuses?.map((subStatus, subIndex) => (
               <Capsule
                 key={subIndex}
                 textColor="text-[#272523]"
                 capsuleColor="bg-[#E8E8E8]"
                 text={subStatus?.name}
                 RightIcon={
                   <CloseIcon
                     onClick={() => showDeleteModal(subStatus)}
                     fontSize="small"
                     style={{
                       fontSize: "12px",
                       cursor: "pointer",
                       marginLeft: "4px",
                     }}
                   />
                 }
               />
             ))}
           </div>
           )}
          </div>
        </div>
      ))}
      <ConfirmationModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        title="Are you sure? Deleting a sub-status will remove it from all orders
      sub-statuses."
        onConfirm={confirmDeletion}
      />
    </div>
  );
};

export default SubStatusLabel;
