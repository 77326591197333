import React from "react";

const ImagePreviewModal = ({ isOpen, imageUrl, onClose }) => {
  if (!isOpen || !imageUrl) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
      onClick={onClose}
    >
      <div className="relative">
        <img
          src={imageUrl}
          alt="Full View"
          className="max-w-full max-h-screen object-contain"
        />
      </div>
    </div>
  );
};

export default ImagePreviewModal;
