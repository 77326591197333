import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LoginPNG from "../images/login.png";
import Logo from "../images/Logo.svg";
import { useDispatch } from "react-redux";
import { login } from "../redux/authActions";
import { addToast } from "../redux/toastSlice";
import { ROOT, SIGNUP, FORGET_PASSWORD, CUSTOMER_ROUTES } from "../constants/FrontendRoutes";
import { EyeIcon } from "../common/icons/";
import { Button } from "@mui/material";
import { LOGIN_TYPE } from "../constants";

const FORM_FIELDS = {
  email: "",
  password: "",
};

function LoginPage() {
  const [formData, setFormData] = useState(FORM_FIELDS);
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = {
      email: formData.email,
      password: formData.password,
    };

    try {
      await dispatch(login({ user, login_type: LOGIN_TYPE.STAFF })).unwrap();
      navigate(ROOT);
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  useEffect(() => {
    const isFormComplete = Object.values(formData).every(
      (field) => field.trim() !== ""
    );
    setIsFormValid(isFormComplete);
  }, [formData]);

  return (
    <div className="bg-gray-100 flex h-screen">
      <div className="w-full lg:w-2/5 my-auto">
        <div className="flex flex-col gap-7 w-[80%] md:w-[45%] lg:w-[70%] mx-auto">
          <img
            alt="logo"
            src={Logo}
            className="w-11 h-auto absolute top-[10%]"
          />
          <h2 className="text-stone-800 text-2xl font-bold font-['Montserrat'] leading-[33.60px]">
            Welcome to Coblr
          </h2>
          <form onSubmit={handleSubmit} className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <label className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter email here"
                  className="w-full px-4 py-2 border rounded-lg"
                  required
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="flex items-center justify-between">
                  <label className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Password
                  </label>
                  <Link
                    to={FORGET_PASSWORD}
                    className="text-green-700 text-xs font-normal font-['SF Pro Display'] leading-tight tracking-tight"
                  >
                    Forget your password?
                  </Link>
                </span>

                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter password here"
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    <EyeIcon />
                  </button>
                </div>
              </div>
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isFormValid}
            >
              Login
            </Button>
          </form>
          <div className="border-[0.5px] border-b-neutral-200"></div>
        </div>
        <div className="flex flex-col gap-1 my-8">
          {/* <span className="flex justify-center items-center gap-2">
            <div className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
              Already a customer?
            </div>
            <Link
              to={CUSTOMER_ROUTES.LOGIN}
              className="text-right text-green-700 text-xs font-normal font-['SF Pro Display'] leading-tight tracking-tight"
            >
              Sign in here
            </Link>
          </span> */}
          <span className="flex justify-center items-center gap-2">
            <div className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
              New to Coblr?
            </div>
            <Link
              to={SIGNUP}
              className="text-right text-green-700 text-xs font-normal font-['SF Pro Display'] leading-tight tracking-tight"
            >
              Sign up now
            </Link>
          </span>
        </div>
      </div>
      <div className="hidden lg:block w-3/5">
        <img
          src={LoginPNG}
          alt="Coblar"
          className="w-full h-screen object-cover"
        />
      </div>
    </div>
  );
}

export default LoginPage;
