import { AddCircleRounded } from "@mui/icons-material";
import {
  Button,
  Chip,
  MenuItem,
  Paper,
  Popover,
  TextField,
} from "@mui/material";
import { isEmpty, last } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRequest, postRequest } from "../../../api";
import { SendIcon } from "../../../common/icons";
import MenuOptionButton from "../../../components/Button/MenuOptionButton";
import IncomingMessage from "../../../components/IncomingMessage";
import Attachment from "../../../components/Message/Attachment";
import OutgoingMessage from "../../../components/OutgoingMessage";
import { STORES } from "../../../constants/BackendRoutes";
import { CONVERSATIONS } from "../../../constants/FrontendRoutes";
import { MESSAGE_STATUS } from "../../../constants/MessageStatus";
import { useActionCable } from "../../../customHooks/useActionCable";
import useHandleDraftMessage from "../../../customHooks/useHandleDraftMessage";
import { selectCurrentUser } from "../../../redux/authSlice";
import { addToast } from "../../../redux/toastSlice";
import { categorizeMessages } from "../../../utils";
import { resizeFile } from "../../../utils/imageResizer";
import {
  destroyDraftMessageFromLS,
  storeDraftMessageToLS,
} from "../../../utils/localStorage";
import { fetchUnreadCount } from "../../../redux/messageAction";

const textFieldInputProps = {
  sx: {
    maxHeight: "70px",
    overflowY: "auto",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      display: "none",
    },
  },
};

const menuIconProps = {
  type: "button",
  style: { color: "#4C8C4A" },
  sx: { p: "10px" },
  "aria-label": "directions",
};

const MessageDetail = forwardRef(function MessageDetail(
  {
    conversationsData,
    selectedOrders,
    selectedRecipient,
    selectedOrderForMessage,
    createNewMessage,
    setIsDetailVisible,
    isCustomer,
    selectedConversation,
    isAllSelectedInDropdown,
    setCreateNewMessage,
    setSelectedOrderForMessage,
    fetchConversations,
    defaultStoreId
  },
  ref
) {
  const userData = useSelector(selectCurrentUser);
  const { id, orderNumber } = useParams();
  const conversationId = parseInt(id);

  const [orderId, setOrderId] = useState(orderNumber || selectedOrderForMessage?.value);

  const [newMessages] = useActionCable("ChatSupportChannel", {
    conversation_id: conversationId,
  });

  const [content, setContent] = useState("");
  const [messages, setMessages] = useState([]);
  const [draftMessage, setDraftMessage] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [_isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [categorizedMessages, setCategorizedMessages] = useState({});
  const [prevMessages, setPrevMessages] = useState([]);
  const fileInputRef = useRef(null);

  const [store, setStore] = useState(selectedConversation?.store);

  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userId = userData?.id;

  const storeBase = `${STORES}/${store?.id || defaultStoreId}`;
  const conversationURL = `${storeBase}/conversations/${id}`;
  const conversationMessagesURL = `${conversationURL}/messages`;

  const newConversationMessagesURL = `${storeBase}/new_conversation`;

  const handleDraftMessage = useHandleDraftMessage();

  const buttonData = [
    {
      name: "Attach repair quote",
      function: () => setIsQuoteModalOpen(true),
      disabled: true,
    },
    {
      name: "Add attachments",
      function: () => fileInputRef.current.click(),
      disabled: false,
    },
  ];

  const updateMessages = useCallback((newMessages) => {
    if (isCustomer) dispatch(fetchUnreadCount());
    if (!isEmpty(Object.keys(newMessages))) {
      setMessages((prevMessages) => {
        return [...prevMessages, newMessages];
      });
    }
  }, []);

  const fetchMessages = useCallback(async () => {
    if (conversationId && store?.id) {
      try {
        const data = await getRequest(conversationMessagesURL, {
          customer_id: selectedConversation?.customerId,
        }, "user&fields=user.name");
        if (!isCustomer) {
          const draft = data.find((message) => {
            return (
              message.status === MESSAGE_STATUS.DRAFT &&
              message.user_id === userId
            );
          });
          if (draft) setContent(draft.content);
          else setContent("");
          setDraftMessage(draft);
        }
        const filteredData = data.filter(
          (message) => message.status !== MESSAGE_STATUS.DRAFT
        );
        setMessages(filteredData);
      } catch (error) {
        navigate(CONVERSATIONS);
        dispatch(addToast("Something went wrong!"));
        setIsDetailVisible(false);
      }
    }
  }, [conversationMessagesURL, store?.id]);

  useEffect(() => {
    if (!isCustomer) handleDraftMessage();
  }, [handleDraftMessage, location, navigate]);

  useEffect(() => {
    if (userData && id && !createNewMessage, store?.id) fetchMessages();
  }, [id, userData, createNewMessage, store?.id, fetchMessages]);

  useEffect(() => {
    if (newMessages) updateMessages(newMessages);
  }, [newMessages, updateMessages]);

  useEffect(() => {
      setOrderId(selectedOrderForMessage?.value);
  }, [selectedOrderForMessage]);

  useEffect(() => {
    setOrderId(null);
    setSelectedOrderForMessage(null);
  }, [createNewMessage]);

  useEffect(() => {
    if (isAllSelectedInDropdown) {
      setFilteredMessages(messages);
    } else if (isEmpty(selectedOrders)) {
      setFilteredMessages(messages);
    } else {
      setFilteredMessages(
        messages.filter((message) => selectedOrders.includes(message.order_id))
      );
    }
  }, [messages, selectedOrders, isAllSelectedInDropdown]);

  useEffect(() => {
    setCategorizedMessages(categorizeMessages(filteredMessages));
  }, [filteredMessages]);

  useEffect(() => {
    setPrevMessages(
      Object.keys(categorizedMessages)
        .filter((key) => key !== "Today" && key !== "Yesterday")
        .sort((a, b) => new Date(a) - new Date(b))
    );
  }, [categorizedMessages]);

  useEffect(() => {
    if (!createNewMessage && !isEmpty(selectedConversation)) {
      setStore(selectedConversation?.store);
      const lastOrderId = last(selectedConversation?.orderIds);
      setOrderId(orderNumber || lastOrderId);

      if (!lastOrderId) {
        setSelectedOrderForMessage(null);
      }
      setDropdownItems(selectedConversation?.orderIds);
    }
  }, [createNewMessage, selectedConversation]);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const fileDetails = files.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
      file,
    }));
    setAttachments((prev) => [...prev, ...fileDetails]);
  };

  const removeImage = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const sendMessage = async () => {
    if (content.trim()) {
      await handleSingleMessage();
      if (!isCustomer) destroyDraftMessageFromLS();
    }
  };

  const handleSingleMessage = async () => {
    if (isCustomer && createNewMessage && !orderId && !selectedOrderForMessage) {
      dispatch(addToast("Please specify order number!"));
      return;
    }

    try {
      setIsBtnDisabled(true);

      const formData = await buildFormData();
      const url = buildRequestUrl();

      const result = await postRequest(url, formData, {
        "Content-Type": "multipart/form-data",
      });

      handlePostRequestSuccess(result);
      resetMessageState();
    } catch (error) {
      dispatch(addToast("Something went wrong!"));
      console.log("Error sending message:", error);
    } finally {
      setIsBtnDisabled(false);
    }
  };

  const buildFormData = async () => {
    const formData = new FormData();
    formData.append("message[content]", content);
    orderId && formData.append("message[order_id]", orderId);
    formData.append("message[user_id]", userId);

    for (const image of attachments) {
      const resizedImage = await resizeFile(image.file);
      formData.append("message[attachments][]", resizedImage);
    }

    if (createNewMessage && !isCustomer) {
      formData.append("user[email]", selectedRecipient?.email);
    } else if (createNewMessage && isCustomer) {
      formData.append("user[email]", userData?.email);
    }

    return formData;
  };

  const buildRequestUrl = () => {
    if (!createNewMessage) return conversationMessagesURL;

    if (!isCustomer) {
      return newConversationMessagesURL;
    }

    const storeId = conversationsData.find((c) =>
      c.orderIds.some((id) => parseInt(id) === parseInt(orderId))
    )?.store?.id;

    return `stores/${storeId}/new_conversation`;
  };

  const handlePostRequestSuccess = async (result) => {
    if (result?.data?.success && createNewMessage) {
      let data = await fetchConversations();
      const conversation = data.find(
        (c) => c?.id === result?.data?.conversation?.id
      );
      if (conversation?.id) {
        const embeddedUrl = isCustomer ? "/customer" : "";
        navigate(`${embeddedUrl}/conversations/${conversation.id}`);
        setCreateNewMessage(false);
        setMessages([]);
      }
    }
  };

  const resetMessageState = () => {
    setAttachments([]);
    setContent("");
    setDraftMessage({});
  };

  useEffect(() => {
    if (ref?.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [prevMessages, newMessages]);

  return (
    <div className="flex flex-col h-full">
      {id && !createNewMessage && (
        <div
          ref={ref}
          className="flex flex-col gap-5 mt-2 overflow-x-hidden sm:overflow-auto"
        >
          {prevMessages.map((date) => renderMessages(date))}
          {renderMessages("Yesterday")}
          {renderMessages("Today")}
        </div>
      )}
      {(id || createNewMessage) && (
        <div className="p-4 mt-auto flex flex-col gap-2">
          <div className="flex gap-1 items-end">
            <Paper className="w-3/5 p-1 flex flex-col flex-grow">
              <div className="flex items-center w-full gap-2">
                <div className="flex-grow">
                  {orderId && (
                    <Chip
                      label={`Order #${orderId}`}
                      style={{
                        marginRight: 5,
                        backgroundColor: "#F3F0E8",
                        padding: "10px 6px",
                        height: "auto",
                      }}
                      onDelete={() => {
                        setOrderId("");
                        setSelectedOrderForMessage(null);
                      }}
                    />
                  )}
                  <TextField
                    value={content}
                    onChange={handleContentChange}
                    placeholder="Type your message here..."
                    multiline
                    variant="outlined"
                    fullWidth
                    InputProps={textFieldInputProps}
                  />
                  {!createNewMessage &&
                    showDropdown &&
                    !isEmpty(dropdownItems) && (
                      <Popover
                        open={showDropdown}
                        anchorEl={anchorEl}
                        onClose={() => setShowDropdown(false)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <div>
                          {dropdownItems.map((item) => (
                            <MenuItem
                              key={item}
                              onClick={() => handleDropdownItemClick(item)}
                            >
                              {item}
                            </MenuItem>
                          ))}
                        </div>
                      </Popover>
                    )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                  />
                </div>
                <MenuOptionButton
                  data={buttonData}
                  icon={<AddCircleRounded />}
                  isMenuHorizontal={true}
                  iconButtonProps={menuIconProps}
                />
              </div>
              {!isEmpty(attachments) && (
                <div className="mt-2 flex flex-wrap gap-2">
                  {renderAttachments()}
                </div>
              )}
            </Paper>
            <Button
              disabled={isBtnDisabled}
              className="min-h-[67px]"
              variant="containedPrimary"
              onClick={sendMessage}
              startIcon={<SendIcon />}
            >
              Send message
            </Button>
          </div>

          {!createNewMessage && (
            <span className="text-[#C9C8C8] font-['Questrial'] text-[12px] font-normal leading-[16.8px] text-left">
              Type # to attach an order
            </span>
          )}
        </div>
      )}
    </div>
  );

  function renderMessages(date) {
    if (categorizedMessages[date]?.length > 0) {
      return (
        <div key={date}>
          <h3 className="text-[#939291] text-center text-xs mb-9">{date}</h3>
          {categorizedMessages[date].map((message, index) => {
            return (
              <div key={`${message.id}-${index}`}>
                {isCustomer ? (
                  message?.user_id === selectedConversation?.customerId ? (
                    <OutgoingMessage
                      text={message.content}
                      time={message.created_at}
                      attachments={message.attachments_data}
                      username={message?.user?.name}
                      orderId={message.order_id}
                      senderId={message?.user_id}
                    />
                  ) : (
                    <IncomingMessage
                      text={message.content}
                      time={message.created_at}
                      attachments={message.attachments_data}
                      username={message?.user?.name}
                      orderId={message.order_id}
                      senderId={message?.user_id}
                    />
                  )
                ) : message?.user_id === selectedConversation?.customerId ? (
                  <IncomingMessage
                    text={message.content}
                    time={message.created_at}
                    attachments={message.attachments_data}
                    username={message?.user?.name}
                    orderId={message.order_id}
                  />
                ) : (
                  <OutgoingMessage
                    text={message.content}
                    time={message.created_at}
                    attachments={message.attachments_data}
                    username={message?.user?.name}
                    orderId={message.order_id}
                    senderId={message?.user_id}
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }

  function renderAttachments() {
    return (
      <div className="flex gap-2 mt-2 max-h-[64px] overflow-x-auto">
        {attachments.map((attachment, index) => (
          <Attachment
            key={index}
            imageUrl={attachment.url}
            fileName={attachment.name}
            onRemove={removeImage}
            index={index}
          />
        ))}
      </div>
    );
  }

  function handleDropdownItemClick(item) {
    setOrderId(item);
    setContent((prev) => prev.replace(/#$/, ""));
    setShowDropdown(false);
  }

  function handleContentChange(e) {
    const value = e.target.value;
    setContent(value);
    if (!isCustomer) {
      storeDraftMessageToLS(
        JSON.stringify({
          conversation_id: conversationId,
          order_id: orderId || null,
          store_id: store?.id,
          content: value,
          user_id: userId,
          status: MESSAGE_STATUS.DRAFT,
          message_id: draftMessage?.id,
        })
      );
    }
    if (value.endsWith("#")) {
      setShowDropdown(true);
      setAnchorEl(e.currentTarget);
    } else {
      setShowDropdown(false);
    }
  }
});

export default MessageDetail;
