import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

const MenuOptionButton = ({ data, id, icon, title, isMenuHorizontal=false, iconButtonProps }) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <div
        className="flex items-center gap-2"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {title && (
          <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug hidden md:block">
            {title}
          </p>
        )}
        <IconButton size="small" {...iconButtonProps}>
          {icon ? icon : <MoreHorizIcon />}
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: isMenuHorizontal ? "top" : "bottom",
        }}
        transformOrigin={{
          vertical: isMenuHorizontal ? "bottom" : "top",
        }}
      >
        {data.map((obj, index) => (
          <MenuItem
            key={index}
            onClick={() => (id ? obj.function(id) : obj.function())}
            disabled={obj.disabled}
          >
            {obj.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default MenuOptionButton;
