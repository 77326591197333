import axios from "axios";
import {
  SHIPMENT_CREATE_LABEL,
  SHIPMENT_GET_RATES,
  TEST_SHIPSTATION_API,
} from "../constants/thirdPartyApiUrls";

const getWeight = (packageInfo) => {
  if (packageInfo.oz === "" || packageInfo.oz == 0) {
    return parseInt(packageInfo.lbs) * 16;
  } else return packageInfo.oz;
};

export async function getShippingInfo(
  shipFromData,
  shipToData,
  packageInfo,
  shipstationToken
) {
  const labelPayload = {
    carrierCode: "ups_walleted",
    fromPostalCode: shipFromData.zipCode,
    toState: shipToData.state,
    toCountry: shipToData.country,
    toPostalCode: shipToData.zipCode,
    toCity: shipToData.city,
    weight: {
      value: getWeight(packageInfo),
      units: "ounces",
    },
    dimensions: {
      units: "inches",
      length: packageInfo.l,
      width: packageInfo.w,
      height: packageInfo.h,
    },
    confirmation: "delivery",
    residential: false,
  };
  try {
    const response = await axios.post(SHIPMENT_GET_RATES, labelPayload, {
      headers: {
        Authorization: `Basic ${shipstationToken}`,
        "Content-Type": "application/json",
      },
    });
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, data: error };
  }
}

export async function createShippingLabel(
  selectedService,
  packageInfo,
  shipFromData,
  shipToData,
  shippingScheduledDate,
  shipstationToken
) {
  const labelPayload = {
    carrierCode: "ups_walleted",
    serviceCode: selectedService.code,
    packageCode: "package",
    confirmation: "delivery",
    shipDate: shippingScheduledDate,
    weight: {
      value: getWeight(packageInfo),
      units: "ounces",
    },
    dimensions: {
      units: "inches",
      length: packageInfo.l,
      width: packageInfo.w,
      height: packageInfo.h,
    },
    shipFrom: {
      name: shipFromData.name,
      company:
        shipFromData.companyName.length === 0
          ? shipFromData.name
          : shipFromData.companyName,
      street1:
        shipFromData.addressLine1 +
        shipFromData.addressLine2 +
        shipFromData.addressLine3,
      city: shipFromData.city,
      state: shipFromData.state,
      postalCode: shipFromData.zipCode,
      phone: shipFromData.phone,
      country: "US",
      residential: false,
    },
    shipTo: {
      name: shipToData.name,
      company:
        shipToData.companyName.length === 0
          ? shipToData.name
          : shipToData.companyName,
      street1:
        shipToData.addressLine1 +
        shipToData.addressLine2 +
        shipToData.addressLine3,
      city: shipToData.city,
      state: shipToData.state,
      postalCode: shipToData.zipCode,
      phone: shipToData.phone,
      country: "US",
      residential: false,
    },
    insuranceOptions: null,
    internationalOptions: null,
    advancedOptions: null,
  };

  try {
    const response = await axios.post(SHIPMENT_CREATE_LABEL, labelPayload, {
      headers: {
        Authorization: `Basic ${shipstationToken}`,
        "Content-Type": "application/json",
      },
    });

    return {
      data: response.data,
      status: true,
    };
  } catch (error) {
    return {
      error,
      status: false,
    };
  }
}

export async function testShipstationApiCredentials(shipstationToken) {
  try {
    const response = await axios.get(TEST_SHIPSTATION_API, {
      headers: {
        Authorization: `Basic ${shipstationToken}`,
        "Content-Type": "application/json",
      },
    });

    return { status: true, data: response.data };
  } catch (error) {
    return {
      status: false,
      data: error.response ? error.response.data : error.message,
    };
  }
}
