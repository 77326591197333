import React, { useState, useEffect, useRef } from "react";
import { InputAdornment } from "@mui/material";

const EditableServicePrice = ({ value, onChange = () => { } }) => {
  const [price, setPrice] = useState(value.toFixed(2));
  const inputRef = useRef(null);

  useEffect(() => {
    const length = typeof price === "string" ? price.length : price.toFixed(2).length;
    if (inputRef.current) {
      inputRef.current.style.width = `${length}ch`;
    }
  }, [price]);

  return (
    <div className="flex items-center border rounded-lg px-1 py-1">
      <InputAdornment position="start" className="text-gray-500">
        $
      </InputAdornment>
      <input
        ref={inputRef}
        type="number"
        className="text-black bg-transparent text-left focus:outline-none no-arrows"
        value={price}
        onChange={(e) => {
          const newValue = parseFloat(e.target.value);
          if (onChange) onChange(newValue);
          setPrice(() => (isNaN(newValue) ? 0 : newValue));
        }}
        style={{
          MozAppearance: "textfield",
        }}
      />
    </div>
  );
};

export default EditableServicePrice;
