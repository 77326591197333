import { useCallback } from 'react';
import { createDraftMessage, saveDraftMessage, deleteDraftMessage } from '../utils/axios/draftMessage';
import { destroyDraftMessageFromLS, retrieveDraftMessageFromLS } from '../utils/localStorage';

const useHandleDraftMessage = () => {
  const handleDraftMessage = useCallback(() => {
    const draft = retrieveDraftMessageFromLS();
    if (!draft) return;

    const isContent = draft.content?.trim() !== '';
    const { message_id } = draft;

    if (message_id) {
      isContent ? saveDraftMessage(draft) : deleteDraftMessage(draft);
    } else if (!message_id && isContent) {
      createDraftMessage(draft);
    }
    destroyDraftMessageFromLS();
  }, []);

  return handleDraftMessage;
};

export default useHandleDraftMessage;
