import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";

const formControlOpenStyle = {
  backgroundColor: "#F1F5EB",
  borderRadius: "12px",
  color: "#4C8A2E",
  boxShadow: "none",
};

const getItemLabel = (item, isObjectArray) =>
  isObjectArray ? item.label : `# ${item}`;
const getItemId = (item, isObjectArray) => (isObjectArray ? item.id : item);

const MultiSelectDropdown = ({
  options = [],
  selectedValues = [],
  onSelectionChange,
  callbackFunction,
  searchTerm,
  onSearchChange,
  enableSearch = false,
  placeholder = "Select items",
  allItemsLabel = "All items",
  IconComponent,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const isObjectArray = !isEmpty(options) && typeof options[0] === "object";

  const filteredOptions = useMemo(() => {
    if (!enableSearch || !searchTerm) return options;
    return options.filter((option) =>
      `${isObjectArray ? option.label : option}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm, enableSearch, isObjectArray]);

  const handleSelectChange = (event) => {
    const { value } = event.target;
    if (value.includes("")) return;

    const isSelectAll = value.includes("all");

    if (isSelectAll) {
      const newIsAllSelected = !isAllSelected;
      setIsAllSelected(newIsAllSelected);

      const selectedIds = newIsAllSelected
        ? isObjectArray
          ? options.map((obj) => obj.id)
          : options
        : [];

      onSelectionChange(selectedIds);
      callbackFunction?.(newIsAllSelected);
    } else {
      onSelectionChange(value);

      const isFullySelected = value?.length === options?.length;

      if (callbackFunction) {
        setIsAllSelected(false);
        callbackFunction(false);
      } else {
        setIsAllSelected(isFullySelected);
      }
    }
  };

  const renderValue = (selected) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {IconComponent && (
        <IconComponent sx={isDropdownOpen ? { color: "#4C8C4A" } : {}} />
      )}
      <span
        style={{
          color: isDropdownOpen ? "#4C8C4A" : "inherit",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "110px",
        }}
      >
        {isEmpty(selected)
          ? placeholder
          : isAllSelected
          ? allItemsLabel
          : `${selected.length} selected`}
      </span>
    </Box>
  );

  const renderSearchField = () => (
    <MenuItem
      value=""
      disableRipple
      disableTouchRipple
      style={{
        background: "#FFFF",
        width: "235px",
      }}
    >
      <TextField
        style={{ borderRadius: "200px" }}
        placeholder="Search by ID"
        value={searchTerm}
        onChange={onSearchChange}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: {
            "& fieldset": {
              marginTop: "6px",
              borderRadius: "8px",
              height: "50px",
            },
          },
        }}
      />
    </MenuItem>
  );

  return (
    <FormControl
      style={{ width: "180px" }}
      sx={isDropdownOpen ? formControlOpenStyle : {}}
    >
      <Select
        multiple
        value={selectedValues}
        onChange={handleSelectChange}
        displayEmpty
        renderValue={renderValue}
        onOpen={() => setIsDropdownOpen(true)}
        onClose={() => setIsDropdownOpen(false)}
        sx={{
          "& .MuiSelect-select": {
            padding: "10px 16px",
            display: "flex",
            alignItems: "center",
          },
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              overflowY: "auto",
            },
          },
        }}
      >
        {enableSearch && renderSearchField()}
        {enableSearch && (
          <Box
            sx={{ marginX: "20px", height: "1px", backgroundColor: "#ddd" }}
          />
        )}
        {!isEmpty(filteredOptions) && (
          <MenuItem value="all">
            <Checkbox checked={isAllSelected} />
            <ListItemText primary={allItemsLabel} />
          </MenuItem>
        )}
        {(filteredOptions || []).map((option) => {
          const optionId = getItemId(option, isObjectArray);
          return (
            <MenuItem key={optionId} value={optionId}>
              <Checkbox checked={selectedValues.includes(optionId)} />
              <ListItemText
                primary={
                  <Typography
                    noWrap
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "140px",
                    }}
                  >
                    {getItemLabel(option, isObjectArray)}
                  </Typography>
                }
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropdown;
