import React, { useState } from "react";
import Button from "@mui/material/Button";

const Introduction = ({ handleNext, activeStep }) => {

  return (
    <div className="flex flex-col gap-3 m-8">
      {activeStep > 0 && (
        <div className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
          Step {activeStep} / 4
        </div>
      )}
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Welcome to Coblr!
      </div>

      <div className="">
        <span className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          Your account has been created! Let's get started on setting up your
          business now!
          <br />
          <br />
          We will save your setup at the end of the onboarding process.
        </span>
      </div>

      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        onClick={handleNext}
      >
        Start setup
      </Button>
    </div>
  );
};

export default Introduction;
