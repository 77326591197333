import { createSlice } from '@reduxjs/toolkit';

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    unreadMessages: 0,
  },
  reducers: {
    setUnreadCount: (state, action) => {
      state.unreadMessages = action.payload;
    },
    resetUnreadCount: (state) => {
      state.unreadMessages = 0;
    },
  },
});

export const { setUnreadCount, resetUnreadCount } = messageSlice.actions;

export default messageSlice.reducer;
