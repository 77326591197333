import React, { useState, useRef, useEffect } from "react";
import { convertTo24HourFormat, diffImageSrc } from "../utils";
import ImagePreviewModal from '../components/Modal/ImagePreviewer';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../redux/authSlice';

const OutgoingMessage = ({ text, time, attachments, username, orderId, senderId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const userData = useSelector(selectCurrentUser);
  const currentUserId = userData?.id;

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const anchor = containerRef.current.querySelector('a');
      if (anchor) {
        const originalHref = anchor.getAttribute('href');
        const newHref = originalHref.replace('/orders/details/', '/customer/orders/details/');
        anchor.setAttribute('href', newHref);
      }
    }
  }, [text]);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="mr-3 flex flex-col items-end my-4">
      <div
        ref={containerRef}
        style={{
          '--text-color-1': '#FFFFFF',
          '--text-color-2': '#FFFFFF',
          '--link-color': '#FFFFFF',
          whiteSpace: 'pre-wrap'
        }}
        className="border rounded-t-[30px] rounded-tr-[30px] rounded-bl-[30px] sm:rounded-t-[45px] sm:rounded-tr-[45px] sm:rounded-bl-[45px] bg-[#4C8C4A] p-4 sm:p-5 w-[300px] text-white text-xs font-extralight sm:font-normal font-['Questrial'] leading-none"
        dangerouslySetInnerHTML={{ __html: text }}
      >
      </div>

      {attachments && attachments.length > 0 && (
        <div className="flex mt-2 flex-col">
          {attachments.map((item, index) => (
            <img
              key={index}
              src={diffImageSrc(item.url)}
              alt={`attachment-${index}`}
              className="h-28 w-28 object-cover rounded-lg mx-1 mt-2 cursor-pointer"
              onClick={() => openModal(diffImageSrc(item.url))}
            />
          ))}
        </div>
      )}
      <div className="text-[#939291] text-xs">
        <span>By {`${currentUserId === senderId ? 'You' : username}`} |</span>
        {orderId && <span> Order #{orderId} |</span>}
        <span> {convertTo24HourFormat(time)}</span>
      </div>
      <ImagePreviewModal isOpen={isOpen} imageUrl={selectedImage} onClose={closeModal} />
    </div>
  );
};

export default OutgoingMessage;
