import {
  Box,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../api";
import { USER_ROLES } from "../../constants";
import { useDebounce } from "../../customHooks/useDebounce";

const BulkList = ({ storeId, userData, setSelectedRecipient }) => {
  const [searchInput, setSearchInput] = useState("");
  const [allCustomers, setAllCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRecipient, setSelectedRecipientInternal] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const debouncedSearchInput = useDebounce(searchInput);

  useEffect(() => {
    const fetchCustomers = async () => {
      setLoading(true);
      try {
        if (storeId) {
          const customers = await getRequest(`/stores/${storeId}/users`, {
            filters: { role: [USER_ROLES.CUSTOMER] },
          });
          setAllCustomers(customers?.data);
          setFilteredCustomers(customers?.data);
        }
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomers();
  }, [userData]);

  useEffect(() => {
    let filtered = allCustomers;
    if (debouncedSearchInput) {
      filtered = allCustomers.filter((customer) => {
        const customerName = customer?.name || "";
        const orderNumber = customer?.id ? customer?.id?.toString() : "";
        const matchesName = customerName
          .toLowerCase()
          .includes(debouncedSearchInput.toLowerCase());
        const matchesOrderNumber = orderNumber.includes(debouncedSearchInput);
        return matchesName || matchesOrderNumber;
      });
    }

    setFilteredCustomers(filtered);
  }, [debouncedSearchInput, allCustomers]);

  const handleSelectRecipient = (recipient) => {
    setSelectedRecipientInternal(recipient);
    setSelectedRecipient(recipient);
    setFilteredCustomers([]);
    setShowDropdown(false);
    setSearchInput("");
  };

  const handleAddRecipient = (event) => {
    if (event.key === "Enter" && searchInput && !selectedRecipient) {
      const newRecipient = {
        email: searchInput,
      };
      setSelectedRecipientInternal(newRecipient);
      setSelectedRecipient(newRecipient);
      setFilteredCustomers([]);
      setSearchInput("");
      setShowDropdown(false);
    }
  };

  const handleRemoveRecipient = () => {
    setSelectedRecipientInternal(null);
    setSelectedRecipient(null);
    setFilteredCustomers(allCustomers);
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => setShowDropdown(false), 100);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {selectedRecipient ? (
          <Chip
            label={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {selectedRecipient?.id && (
                  <span style={{ color: "gray", fontSize: "12px" }}>
                    Customer #{selectedRecipient?.id}
                  </span>
                )}
                <span
                  style={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {selectedRecipient?.name || selectedRecipient?.email}
                </span>
              </div>
            }
            onDelete={handleRemoveRecipient}
            style={{
              marginRight: 5,
              backgroundColor: "#F3F0E8",
              padding: "10px 6px",
              height: "auto",
            }}
          />
        ) : (
          <input
            type="text"
            value={searchInput}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleAddRecipient}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Type to search or add"
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          />
        )}
      </Box>

      {showDropdown && filteredCustomers.length > 0 && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 1000,
            backgroundColor: "white",
            border: "1px solid transparent",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            width: "180px",
            maxHeight: "302px",
            overflowY: "auto",
            marginTop: "26px",
            borderRadius: "4px",
          }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <List>
              {filteredCustomers.map((customer) => (
                <Box
                  key={customer.id}
                  sx={{
                    margin: "4px 14px",
                  }}
                >
                  <ListItem
                    sx={{
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#F3F0E880",
                      },
                      cursor: "pointer",
                      display: "flex",
                    }}
                    onMouseDown={() => handleSelectRecipient(customer)}
                  >
                    <ListItemText
                      primary={
                        <Box>
                          <div style={{ color: "#939291", fontSize: "14px" }}>
                            #{customer?.id}
                          </div>
                          <div
                            className="font-['Questrial'] font-extrabold text-[#272523] text-base"
                            style={{ fontWeight: "400", fontSize: "16px" }}
                          >
                            {customer?.name}
                          </div>
                        </Box>
                      }
                    />
                  </ListItem>
                </Box>
              ))}
            </List>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BulkList;
