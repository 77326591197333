import React from "react";
import SubStatusLabel from "../Order/SubStatusLabel";

const OrderManagement = () => {
  return (
    <div className="h-full gap-6 p-8 rounded-tl-[8px] w-full bg-white rounded-lg">
      <div className=" gap-[8px]">
        <div className="w-[184px] h-[25px] text-stone-800 text-lg font-['Montserrat'] font-semibold leading-[25.2px] text-left">
          Order Management
        </div>
        <span className="h-[44px] gap-0 text-stone-800 text-base font-normal font-['Questrial'] text-left mt-5">
          Create custom sub-statuses to better organize your orders.
          Sub-statuses will be seen by customers. You can add up to 10
          sub-statuses for each status.
        </span>
      </div>
      <SubStatusLabel />
    </div>
  );
};

export default OrderManagement;
