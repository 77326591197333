import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BaseList from "../../../common/components/Conversations/BaseList";
import { CUSTOMER_ROUTES } from "../../../constants/FrontendRoutes";

const List = ({
  storeId,
  userData,
  conversationsData,
  setSelectedConversation,
  setCreateNewMessage,
}) => {
  const { id } = useParams();
  const conversationId = parseInt(id);

  const [conversations, setConversations] = useState([]);

  const handleClickFunctions = (conversation) => {
    setSelectedConversation(conversation);
    setCreateNewMessage(false);
  };

  useEffect(() => {
    setConversations(conversationsData);
  }, [conversationsData]);

  return (
    <BaseList
      isCustomer={true}
      storeId={storeId}
      userData={userData}
      conversations={conversations}
      conversationId={conversationId}
      navigationLink={CUSTOMER_ROUTES.MESSAGES_DETAILS}
      setConversations={setConversations}
      handleClickFunctions={handleClickFunctions}
    />
  );
};

export default List;
