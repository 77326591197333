import CircleIcon from "@mui/icons-material/Circle";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { updateRequest } from "../../../api";
import {
  MARK_ALL_MESSAGES_READ,
  STORES,
} from "../../../constants/BackendRoutes";
import { useActionCable } from "../../../customHooks/useActionCable";
import { formatTimeDifference } from "../../../utils";
import { useDispatch } from "react-redux";
import { fetchUnreadCount } from '../../../redux/messageAction';

const BaseList = ({
  isCustomer,
  userData,
  conversations,
  conversationId,
  navigationLink,
  setConversations,
  handleClickFunctions,
}) => {
  const dispatch = useDispatch();
  const [newMessage] = useActionCable("ConversationsListChannel");

  const findConversationByID = (id) => conversations?.find((c) => c.id === id);
  const currentConversation = findConversationByID(conversationId);

  const markMessagesRead = async (conversationId, storeId) => {
    await updateRequest(
      `${STORES}/${storeId}/conversations/${conversationId}${MARK_ALL_MESSAGES_READ}`
    );
  };

  const handleClick = async (id) => {
    const conversation = findConversationByID(id);
    if (conversation.highlighted) {
      await markMessagesRead(id, conversation?.store?.id);
    }
    if (conversation.highlighted && isCustomer) dispatch(fetchUnreadCount());
    setConversations(
      conversations.map((conversation) =>
        conversation.id === id
          ? { ...conversation, highlighted: false }
          : conversation
      )
    );
    handleClickFunctions(conversation);
  };

  useEffect(() => {
    if (isCustomer) dispatch(fetchUnreadCount());
    let conversation = currentConversation;
    let highlighted = true;

    if (newMessage.conversation_id === conversationId) {
      if (newMessage.user_id === userData.id) {
        highlighted = false;
      } else if (
        isCustomer ||
        (!isCustomer && conversation?.customerId === newMessage.user_id)
      ) {
        markMessagesRead(conversationId, conversation?.store?.id);
        highlighted = false;
      }
    } else {
      conversation = findConversationByID(newMessage.conversation_id);
      if (!isCustomer && newMessage.user_id !== conversation?.customerId) {
        highlighted = false;
      }
    }

    setConversations(
      conversations.map((conversation) => {
        if (conversation.id === newMessage.conversation_id) {
          return {
            ...conversation,
            highlighted,
            message: newMessage.content,
            time: newMessage.created_at,
          };
        } else {
          return conversation;
        }
      })
    );
  }, [newMessage]);

  return (
    <div className="flex flex-col">
      {(conversations || []).map((conversation) => (
        <Link
          className={`max-h-[96px] overflow-y-auto ${
            conversation.id === conversationId ? "sm:bg-[#F3F0E8]" : ""
          }`}
          key={conversation.id}
          to={navigationLink.replace(":id", conversation?.id)}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleClick(conversation.id);
          }}
        >
          <div
            className={
              "mx-5 px-0 py-2 sm:py-5 border-b border-solid border-[#F3F0E8] "
            }
          >
            <div className="flex justify-between relative">
              <div className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug overflow-hidden whitespace-nowrap text-ellipsis">
                {conversation.displayName}
              </div>
              <div className="text-right text-stone-400 text-xs font-normal font-['Questrial'] leading-none">
                {formatTimeDifference(conversation.time)}
              </div>
              {conversation.highlighted && (
                <div
                  style={{
                    position: "absolute",
                    transform: "translate(0, 5px)",
                    left: "-5%",
                  }}
                >
                  <CircleIcon
                    sx={{
                      width: "10px",
                      height: "10px",
                      color: "#D84141",
                      position: "absolute",
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className="text-xs mt-2"
              dangerouslySetInnerHTML={{ __html: conversation.message }}
            ></div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default BaseList;
