import { postRequest, updateRequest, deleteRequest } from "../../api";
import { MESSAGE_STATUS } from "../../constants/MessageStatus";

export const createDraftMessage = async (draft) => {
  const { conversation_id, store_id, content, user_id, order_id } = draft;
  await postRequest(`stores/${store_id}/conversations/${conversation_id}/messages`, {
    message: {
      content,
      user_id,
      order_id,
      store_id,
      status: MESSAGE_STATUS.DRAFT,
    },
  });
};

export const saveDraftMessage = async (draft) => {
  const { conversation_id, store_id, content, user_id, order_id, message_id } = draft;
  await updateRequest(`stores/${store_id}/conversations/${conversation_id}/messages/${message_id}`, {
    message: {
      content,
      user_id,
      order_id,
      store_id,
      status: MESSAGE_STATUS.DRAFT,
    },
  });
};

export const deleteDraftMessage = async (draft) => {
  const { conversation_id, store_id, message_id } = draft;
  await deleteRequest(`stores/${store_id}/conversations/${conversation_id}/messages/${message_id}`);
};
