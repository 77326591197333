import { MailRounded } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getRequest } from "../../../api";
import { LocationIcon } from "../../../common/icons";
import AutoCompleteInput from "../../../components/Form/Field/AutoComplete";
import FieldItem from "../../../components/Form/FieldItem";
import { selectCurrentUser } from "../../../redux/authSlice";
import { formatDate } from "../../../utils";
import MultiSelectDropdown from "../../Form/MultiSelectDropdown";
import MessageDetail from "./Detail";
import MessageList from "./List";

const CustomerConversations = () => {
  const ref = useRef();
  const userData = useSelector(selectCurrentUser);
  const { id } = useParams();
  const conversationId = parseInt(id);

  const [selectedConversation, setSelectedConversation] = useState({});

  const [lastMessage, setLastMessage] = useState({});
  const [allConversations, setAllConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orders, setOrders] = useState([]);

  const [isAllSelectedInDropdown, setIsAllSelectedInDropdown] = useState(false);

  const storeId = userData?.stores_as_customer?.[0]?.store_id;

  const fetchConversations = async () => {
    try {
      const response = await getRequest(
        "customer/conversations",
        {},
        "store,customer&fields=store.id,store.name"
      );

      if (response?.length > 0) {
        const data = response.map((conversation) => {
          const lastMessage = conversation?.last_message;
          const highlighted =
            lastMessage &&
            lastMessage.user_id !== conversation?.customer?.id &&
            !lastMessage.is_read;

          const formattedData = {
            id: conversation?.id,
            displayName: conversation?.store?.name,
            lastOrderId: conversation?.last_order?.id,
            lastOrderDate: formatDate(conversation?.last_order?.created_at),
            message: lastMessage?.content,
            messageId: lastMessage?.id,
            time: lastMessage?.created_at,
            customerId: conversation?.customer?.id,
            highlighted,
            orderIds: conversation?.store_order_ids,
            store: { id: conversation?.store?.id },
          };

          if (conversation?.id === conversationId) {
            setSelectedConversation(formattedData);
          }

          return formattedData;
        });

        setAllConversations(data);
        setFilteredConversations(data);
        return data;
      }
    } catch (error) {
      console.error("Failed to fetch conversations:", error);
    }
  };
  useEffect(() => {
    fetchConversations();
  }, [conversationId]);

  useEffect(() => {
    if (selectedConversation) {
      setSelectedOrders([]);
      setOrders(selectedConversation?.orderIds);
    }
  }, [selectedConversation]);

  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (userData) {
      const data = userData?.stores_as_customer?.map((storeUser) => {
        const { id, name } = storeUser?.store;
        return {
          id,
          label: name,
        };
      });
      const sortedStores = data?.sort((a, b) => a.id - b.id) || [];
      setStores(sortedStores);
    }
  }, [userData]);

  useEffect(() => {
    if (selectedStores.length === 0) {
      setFilteredConversations(allConversations);
    } else if (selectedStores?.length === stores?.length) {
      setFilteredConversations(allConversations);
    } else {
      const filteredData = (allConversations || []).filter((c) =>
        selectedStores.some((id) => id === c?.store?.id)
      );
      setFilteredConversations(filteredData);
    }
  }, [selectedStores]);

  const [createNewMessage, setCreateNewMessage] = useState(false);
  const [selectedOrderForMessage, setSelectedOrderForMessage] = useState(null);

  const allOrderIdsMapped = allConversations.flatMap((conversation) =>
    conversation.orderIds.map((id) => ({
      title: `#${id}`,
      value: `${id}`,
    }))
  );

  return allConversations.length > 0 ? (
    <div
      className={
        "flex sm:flex-row justify-between sm:ps-6 col-span-5 sm:col-span-2 bg-white md:bg-transparent"
      }
    >
      <div
        className={`sm:flex flex-col border-r border-[#F3F0E8] ${
          id || createNewMessage ? "hidden" : "flex"
        } pt-6 sm:w-[30%] w-full`}
      >
        <div className="flex flex-row sm:flex-col md:flex-col lg:flex-row justify-between pb-6 px-3 ">
          <span className="text-[#272523] mt-2 text-xs sm:text-lg font-semibold font-['Montserrat']">
            All Messages
          </span>
          <MultiSelectDropdown
            options={stores}
            selectedValues={selectedStores}
            onSelectionChange={setSelectedStores}
            enableSearch={false}
            placeholder="Select stores"
            allItemsLabel="All stores"
            itemLabel={(item) => item.label}
            IconComponent={LocationIcon}
          />
        </div>
        <Box
          sx={{ height: `calc(100vh - 300px)` }}
          className="overflow-auto my-2 sm:my-0 gap-2 pb-4 flex flex-col justify-between"
        >
          <MessageList
            userData={userData}
            lastMessage={lastMessage}
            conversationsData={filteredConversations}
            setLastMessage={setLastMessage}
            setSelectedConversation={setSelectedConversation}
            setCreateNewMessage={setCreateNewMessage}
          />
        </Box>
        <Button
          sx={{ margin: "40px" }}
          onClick={() => {
            setCreateNewMessage(true);
            setSelectedConversation({});
          }}
          className="h-[42px]"
          variant="containedPrimary"
          startIcon={<MailRounded />}
        >
          Send a new message
        </Button>
      </div>

      {(id || createNewMessage) && (
        <div className="flex flex-col sm:w-[70%] w-full sm:block">
          <div className="flex md:flex-row flex-col items-start gap-3 rounded-tl-lg rounded-tr-lg border-b border-[#F3F0E8] p-4 col-span-3 bg-white">
            <>
              <div className="flex gap-[5px]">
                <Link
                  className="sm:hidden"
                  to={"/customer/conversations"}
                  onClick={() => setCreateNewMessage(false)}
                >
                  <ChevronLeftIcon className="text-gray-600" />
                </Link>
                <p className="font-['Montserrat'] font-normal text-base text-[#272523] whitespace-nowrap">
                  Send to:
                </p>
                <span
                  className="text-[#272523] text-lg font-semibold font-['Montserrat'] mr-6 whitespace-nowrap"
                  style={{ lineHeight: "1.5em" }}
                >
                  Repair Team
                </span>
              </div>
            </>
            {createNewMessage && (
              <Box>
                <FieldItem>
                  <Typography width="30%" variant="body">
                    Order #
                  </Typography>
                  <AutoCompleteInput
                    name="order"
                    label="Please enter"
                    options={allOrderIdsMapped}
                    value={selectedOrderForMessage}
                    onChange={(_e, value) => setSelectedOrderForMessage(value)}
                  />
                </FieldItem>
              </Box>
            )}
            {!createNewMessage && (
              <MultiSelectDropdown
                options={orders}
                selectedValues={selectedOrders}
                onSelectionChange={setSelectedOrders}
                callbackFunction={setIsAllSelectedInDropdown}
                searchTerm={searchTerm}
                onSearchChange={(e) => setSearchTerm(e.target.value)}
                enableSearch={true}
                placeholder="Select orders"
                allItemsLabel="All messages"
                itemLabel={(item) => `${item}`}
              />
            )}
          </div>
          <React.Fragment>
            <Box
              sx={{ height: "calc(100vh - 155px)" }}
              className={`border-t border-[#F3F0E8] p-4 col-span-5 sm:col-span-3 bg-white`}
            >
              <MessageDetail
                ref={ref}
                storeId={storeId}
                userData={userData}
                lastMessage={lastMessage}
                conversationsData={allConversations}
                selectedOrders={selectedOrders}
                createNewMessage={createNewMessage}
                selectedOrderForMessage={selectedOrderForMessage}
                selectedConversation={selectedConversation}
                isAllSelectedInDropdown={isAllSelectedInDropdown}
                fetchConversations={fetchConversations}
                setLastMessage={setLastMessage}
                setCreateNewMessage={setCreateNewMessage}
                setSelectedConversation={setSelectedConversation}
                setSelectedOrderForMessage={setSelectedOrderForMessage}
              />
            </Box>
          </React.Fragment>
        </div>
      )}
    </div>
  ) : (
    <div className="flex items-center justify-center h-screen -mt-20">
      <span className="font-bold text-2xl">No Conversation Found</span>
    </div>
  );
};

export default CustomerConversations;
