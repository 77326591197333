import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
import moment from "moment";

const CustomDatePicker = ({ label, textFieldStyles, minDate = moment() , ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        minDate={minDate}
        slots={{
          textField: (params) => (
            <TextField
              {...params}
              size="small"
              placeholder="MM/DD/YYYY"
              style={{ width: "100%", ...textFieldStyles }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderColor: "#D0D5DD",
                  borderRadius: "8px",
                  "& fieldset": {
                    borderColor: "#D0D5DD",
                  },
                  "&:hover fieldset": {
                    borderColor: "#4C8C4A", // Add hover effect if needed
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#4C8C4A", // Add focus effect if needed
                  },
                },
              }}
            />
          ),
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
