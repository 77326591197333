import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="w-full"
    >
      {value === index && <section>{children}</section>}
    </div>
  );
}

export default function BasicTabs({ setValue = () => {}, value = null, list = [], disabled = false, ...props }) {
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <Tabs
      sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
      value={value}
      onChange={handleChange}
      variant="scrollable"
      {...props}
    >
      {list.map((item, index) => {
        return (
          <Tab
            sx={{ textTransform: "none" }}
            label={item}
            key={index}
            disabled={disabled}
            {...a11yProps(index)}
          />
        );
      })}
    </Tabs>
  );
}
