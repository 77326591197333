import React, { useState, useRef } from "react";
import EntityPage from "../components/EntityPage";
import MyAccount from "../components/CustomerAccount/MyAccount";
import Address from "../components/CustomerAccount/Address";
import Notifications from "../components/CustomerAccount/Notifications";
import { Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../redux/authSlice";
import { addToast } from "../redux/toastSlice";
import { updateRequest } from "../api";
import { hasNonEmptyValues } from "../utils";
import { setLoading } from "../redux/loadingSlice";
import SubscriptionFlow from "../components/Modal/SubscriptionFlow";
import { fetchUserData } from "../redux/authActions";

const CustomerAccount = () => {
  const [activeLink, setActiveLink] = useState("my_account");
  const [openPlanModal, setOpenPlanModal] = useState(false);

  const [accountDetailChanges, setAccountDetailChanges] = useState({});
  const [addressDetailChanges, setAddressDetailChanges] = useState({});
  const [isEditingPassword,setIsEditingPassword]=useState(false);
  const [notificationsUpdateChanges, setNotificationsUpdateChanges] = useState(
    {}
  );

  const accountRef = useRef(null);
  const addressRef = useRef(null);
  const notificationRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const linksConfig = [
    {
      id: "my_account",
      label: "My Account",
      component: MyAccount,
      props: { userData, setAccountDetailChanges, setIsEditingPassword},
      ref: accountRef,
      shouldRender:true,
    },
    {
      id: "Address",
      label: "Address",
      component: Address,
      props: { userData, setAddressDetailChanges, display: true },
      ref: addressRef,
      shouldRender:true,
    },
    {
      id: "notifications",
      label: "Notifications",
      component: Notifications,
      props: { userData, setNotificationsUpdateChanges, display: false },
      ref: notificationRef,
      shouldRender:false
    },
  ];

  const isSaveDisabled = () => {
    const { new_password = "", confirm_new_password = "",existing_password="" } =
      accountDetailChanges;
    const accountChangesExist = hasNonEmptyValues(accountDetailChanges);
    const addressChangesExist = hasNonEmptyValues(addressDetailChanges);
    const notificationsChangesExist = hasNonEmptyValues(
      notificationsUpdateChanges
    );
    const isExistingPasswordFilled=existing_password.trim() !== "";
    const isNewPasswordFilled = new_password.trim() !== "";
    const isConfirmPasswordFilled = confirm_new_password.trim() !== "";
    const passwordsMatch = new_password === confirm_new_password;
    if (accountChangesExist || addressChangesExist || notificationsChangesExist) {
      return false;
    }
    if (isEditingPassword)
    {
      if(
        !isExistingPasswordFilled ||
        !isNewPasswordFilled ||
        !isConfirmPasswordFilled ||
        !passwordsMatch
      ) {
        return true;
      }
    }
    return true;
  };

  const updateCustomer = async () => {
    try {
      dispatch(setLoading(true));
      const response = await updateRequest(
        `stores/${userData?.stores_as_customer?.[0]?.store.id}/users/${userData?.id}/`,
        {
          user: {
            ...accountDetailChanges,
            ...addressDetailChanges,
            ...notificationsUpdateChanges,
          },
        },
        { "Content-Type": "application/json" }
      );
      if (response.status === 200) {
        dispatch(addToast("Updated successfully."));
        dispatch(fetchUserData({ id: userData?.id, login_type: 'customer' })).then(() => {
          setAccountDetailChanges({});
          setAddressDetailChanges({});
          setNotificationsUpdateChanges({});
        });
      }
    } catch (error) {
      dispatch(
        addToast(error.message || "An error occurred during the update.")
      );
      console.error("Error during customer update:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleScrollToSection = (sectionRef, linkId) => {
    setActiveLink(linkId);
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <EntityPage
      className="bg-[#F8F8F8]"
      stickyBottomBar
      hideNotificationIcon={true}
    >
      <div className="flex gap-6">
        <div className="w-[240px] h-fit p-8 bg-white rounded-lg hidden md:block">
          <div className="flex-col justify-start items-start gap-2 flex">
            {linksConfig.map((link) => (
              link.shouldRender &&
              <a
                key={link.id}
                className={`text-base font-normal font-['Questrial'] leading-snug hover:bg-stone-100 w-full px-2 ${
                  activeLink === link.id ? "text-stone-800" : "text-stone-400"
                }`}
                href={`#${link.id}`}
                onClick={() => handleScrollToSection(link.ref, link.id)}
              >
                {link.label}
              </a>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-6 w-full h-[calc(100vh-82px-40px-80px)] pb-20">
          {linksConfig.map((link) => {
            const Component = link.component;
            return (
              <div key={link.id} ref={link.ref}>
                <Component {...link.props} />
              </div>
            );
          })}
        </div>
      </div>

      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          padding: "16px 0",
          display: "flex",
          flexDirection: "row-reverse",
          gap: "28px",
        }}
        elevation={8}
      >
        <div className="flex flex-row gap-3 mx-5">
          <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button
            variant="containedPrimary"
            disabled={isSaveDisabled()}
            onClick={updateCustomer}
          >
            Save changes
          </Button>
        </div>
      </Paper>
      <SubscriptionFlow open={openPlanModal} setOpen={setOpenPlanModal} />
    </EntityPage>
  );
};

export default CustomerAccount;
