import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import InputMask from "react-input-mask";
import { CHECKBOX_DISPLAY_IN_EMAIL, COUNTRIES, STATES_PER_COUNTRY } from "../../constants";
import Selector from "../Form/Field/AutoComplete";

const AddressFields = ({
  prefix,
  address,
  setBusinessDetail,
  setBusinessDetailChanges,
  displayCityValue,
  handleDisplayInEmailCheckbox,
  idDisplayCityCheckbox,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(address?.country || "US");

  const handleChange = (field, value) => {
    setBusinessDetail((prevDetail) => {
      const existingAddressIndex = prevDetail.addresses?.findIndex(
        (addr) => addr.address_type === prefix.toLowerCase(),
      );

      const updatedAddresses = [...prevDetail.addresses];

      if (existingAddressIndex !== -1) {
        updatedAddresses[existingAddressIndex] = {
          ...updatedAddresses[existingAddressIndex],
          [field]: value,
        };
      } else {
        updatedAddresses.push({
          address_type: prefix.toLowerCase(),
          line: "",
          zipcode: "",
          city: "",
          state: "",
          country: "",
          [field]: value,
        });
      }

      setBusinessDetailChanges((prev) => ({
        ...prev,
        addresses: updatedAddresses,
      }));

      return {
        ...prevDetail,
        addresses: updatedAddresses,
      };
    });
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    handleChange(name, value);
  };

  const handleSelectorChange = (field) => (_, value) => {
    handleChange(field, value?.value);
    if (field === "country") {
      setSelectedCountry(value?.value);

      setBusinessDetail((prevDetail) => {
        const updatedAddresses = prevDetail.addresses.map((address) =>
          address.address_type === prefix.toLowerCase()
            ? { ...address, country: value?.value, zipcode: "", city: "", line: "", state: "" }
            : address
        );

        setBusinessDetailChanges((prev) => ({
          ...prev,
          addresses: updatedAddresses,
        }));

        return {
          ...prevDetail,
          addresses: updatedAddresses,
        };
      });
    }
  };

  useEffect(() => {
    setSelectedCountry(address?.country);
  }, [address.country]);

  return (
    <React.Fragment>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-col gap-2 w-full basis-[33%]">
          <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Country
          </div>
          <Selector
            name="country"
            label="Please select"
            size="large"
            options={COUNTRIES}
            onChange={handleSelectorChange("country")}
            value={COUNTRIES.find((country) => country?.value === address?.country)}
          />
        </div>
        <div className="flex flex-col gap-2 w-full basis-[67%]">
          <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            {prefix} address
          </div>
          <TextField
            name="line"
            placeholder="Please enter"
            variant="outlined"
            value={address.line}
            onChange={handleFieldChange}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-col gap-2 w-full">
          <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            City
          </div>
          <TextField
            name="city"
            placeholder="New York"
            variant="outlined"
            value={address.city}
            onChange={handleFieldChange}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Province/State/Region
          </div>
          <Selector
            name="state"
            label="Please select"
            size="large"
            options={STATES_PER_COUNTRY[selectedCountry] || []}
            onChange={handleSelectorChange("state")}
            value={STATES_PER_COUNTRY?.[selectedCountry]?.find((state) => state?.value === address?.state) || null}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Zip/Postal Code
          </div>
          <InputMask
            mask={selectedCountry === "CA" ? "a9a 9a9" : "99999"}
            value={address?.zipcode}
            onChange={handleFieldChange}
          >
            {() => (
              <TextField
                type="text"
                name="zipcode"
                placeholder="12345"
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>
      {idDisplayCityCheckbox && (
        <FormControlLabel
          control={
            <Checkbox
              name="display_city"
              inputProps={{ "aria-label": "controlled" }}
              checked={displayCityValue}
              onChange={handleDisplayInEmailCheckbox}
            />
          }
          label={CHECKBOX_DISPLAY_IN_EMAIL}
        />
      )}
    </React.Fragment>
  );
};

export default AddressFields;
