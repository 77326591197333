import React from "react";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubStatusLabel from "../Order/SubStatusLabel";

const SubStatusModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex items-center justify-center h-full p-4">
        <div className="w-full max-w-[699px] bg-white rounded-lg p-6 sm:w-[90%] md:w-[80%] lg:w-[699px]">
          <div className="ml-3 flex justify-between items-center text-stone-800 text-lg font-bold">
            <span>Create sub-status labels</span>
            <Button onClick={onClose} color="secondary">
              <CloseIcon fontSize="small" />
            </Button>
          </div>
          <div className="text-stone-800 text-base font-normal mt-3 ml-[12px]">
            Create a sub-status below to better organize your order. The
            sub-status will need to be assigned to a status. You can always
            adjust the labels in settings.
          </div>
          <SubStatusLabel onClose={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default SubStatusModal;
